import header from '@/assets/tiff/3.jpg'
import Anim from '@/components/Anim'
import Button from '@/components/Button'
import Page from '@/components/Page'

import FAQ from './FAQ'
import OurDriver from './OurDriver'
import OurService from './OurService'

const Advertiser = () => {
  const openBrochureLink = () => {
    window.open('https://drive.google.com/file/d/1JDCOpaJr0-MXiSp7SSTSUDAb9YJ-h8tP/view', '_blank')
  }

  return (
    <Page>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>Advertise with Arund</Page.Header.Title>
        <Page.Header.Subtitle>
          With precise targeting and driver selection, we maximize your brand awareness
        </Page.Header.Subtitle>

        <Anim.Sticky className='mt-10'>
          <Button
            className='text-xl text-black bg-white py-4 px-6 w-auto'
            onClick={openBrochureLink}
            external
          >
            View Introductory Brochure
          </Button>
        </Anim.Sticky>
      </Page.Header>
      <OurService />
      {/* <WhyAdvertiser /> */}
      <OurDriver />

      <hr className='w-4/5 mx-auto border-t-2 pb-10 md:max-w-6xl' />

      <FAQ />
    </Page>
  )
}

export default Advertiser
