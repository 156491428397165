/* eslint-disable @typescript-eslint/no-explicit-any */
/* This is because types of html components are messy */
import { useEffect, useState } from 'react'
import Markdown from 'react-markdown'

type Props = {
  src: string
}

const MarkdownView = ({ src }: Props) => {
  const [markdown, setMarkdown] = useState('')

  useEffect(() => {
    fetch(src)
      .then((res) => res.text())
      .then((markdown) => setMarkdown(markdown))
  }, [src])

  return (
    <Markdown
      components={{
        h1: ({ children }: any) => (
          <h1 className='text-3xl md:text-5xl font-bold text-gray-900 mt-8 mb-3 md:mt-12 md:mb-4'>
            {children}
          </h1>
        ),
        h2: ({ children }: any) => (
          <h1 className='text-xl md:text-3xl font-bold text-gray-800 mt-6 mb-2 md:mt-8 md:mb-3'>
            {children}
          </h1>
        ),
        h3: ({ children }: any) => (
          <h1 className='text-lg md:text-xl font-bold text-gray-700 mt-4 mb-1 md:mt-6 md:mb-2'>
            {children}
          </h1>
        ),
        p: ({ children }: any) => <h1 className='text-gray-900 mb-4'>{children}</h1>,
      }}
    >
      {markdown}
    </Markdown>
  )
}

export default MarkdownView
