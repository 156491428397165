import { MotionProps } from 'framer-motion'

import { DEFAULT_TRANSITION } from '@/utils/base-motion'
import { THEME } from '@/utils/tailwind-config'

export const containerMotion: MotionProps = {
  variants: {
    light: {
      backgroundColor: THEME.colors.gray[100],
      color: THEME.colors.gray[900],
    },
    dark: {
      backgroundColor: THEME.colors.gray[800],
      color: THEME.colors.gray[100],
    },
    hover: {
      scale: 1.02,
      backgroundColor: THEME.colors.gray[800],
      color: THEME.colors.gray[100],
    },
  },
  initial: 'light',
  whileHover: 'hover',
  transition: DEFAULT_TRANSITION,
}

export const iconMotion: MotionProps = {
  variants: {
    open: {
      rotate: 180,
      origin: 'center',
    },
    closed: {
      rotate: 0,
      origin: 'center',
    },
  },
  initial: 'closed',
}

export const contentMotion: MotionProps = {
  variants: {
    closed: { height: 0 },
    open: { height: 'auto' },
  },
  initial: 'closed',
  animate: 'open',
  exit: 'closed',
  transition: DEFAULT_TRANSITION,
}
