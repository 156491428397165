import { useEffect, useRef } from 'react'
import { Outlet, Route, Routes, useLocation, useSearchParams } from 'react-router-dom'

import Footer from '@/components/Footer'
import Header from '@/components/Header'
import ModalProvider from '@/components/providers/ModalProvider'
import ThemeProvider from '@/components/providers/ThemeProvider'
import Adhoc from '@/pages/Adhoc'
import Advantages from '@/pages/Advantages'
import Advertiser from '@/pages/Advertiser'
import AI from '@/pages/AI'
import Analytics from '@/pages/Analytics'
import AppPage from '@/pages/AppPage'
import Arunner from '@/pages/Arunner'
import Blog from '@/pages/Blog'
import DynamicApproach from '@/pages/Blog/DynamicApproach'
import RethinkingAdvertising from '@/pages/Blog/RethinkingAdvertising'
import WhySCA from '@/pages/Blog/WhySCA'
import Campaign from '@/pages/Campaign'
import Career from '@/pages/Career'
import ContactUs from '@/pages/ContactUs'
// import Error from '@/pages/Error'
import Gallery from '@/pages/Gallery'
import Home from '@/pages/Home'
import FAQMobile from '@/pages/Legal/FAQMobile'
import PrivacyPolicy from '@/pages/Legal/PrivacyPolicy'
import TermsCondition from '@/pages/Legal/TermsCondition'
import WechatQRCode from '@/pages/WechatQRCode'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

const WithHeaderFooter = () => {
  return (
    // Need `clip` for 1) framer-motion animation space and 2) sticky position
    <div className='overflow-x-clip w-screen'>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

function App() {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const qrcodeRef = useRef<string>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  useEffect(() => {
    const qrFrom = searchParams.get('f') || ''
    if (!qrcodeRef.current) {
      window.gtag('event', 'qrcode', {
        event: qrFrom,
        event_category: 'engagement',
        event_label: 'scan_from',
      })
    }
    qrcodeRef.current = qrFrom
  }, [searchParams])

  return (
    <div className='flex flex-col min-h-screen'>
      <ThemeProvider>
        <ModalProvider>
          <Routes>
            <Route path='/' element={<WithHeaderFooter />}>
              {/* <Error /> */}
              <Route path='/' element={<Home />} />
              <Route path='/advertiser' element={<Advertiser />} />
              <Route path='/driver' element={<Arunner />} />
              <Route path='/contact' element={<ContactUs />} />
              <Route path='/career' element={<Career />} />
              <Route path='/campaign' element={<Campaign />} />
              <Route path='/adhoc' element={<Adhoc />} />
              <Route path='/advantages' element={<Advantages />} />
              <Route path='/analytics' element={<Analytics />} />
              <Route path='/ai' element={<AI />} />
              <Route path='/app' element={<AppPage />} />
              <Route path='/gallery' element={<Gallery />} />
              <Route path='/blog' element={<Blog />} />
              <Route path='/blog/why-sca' element={<WhySCA />} />
              <Route path='/blog/dynamic-approach' element={<DynamicApproach />} />
              <Route path='/blog/rethinking-advertising' element={<RethinkingAdvertising />} />
            </Route>

            {/* <Route path='/driver-survey' element={<ArunnerForm />} /> */}
            <Route path='/terms-and-conditions' element={<TermsCondition />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/driver-faq' element={<FAQMobile />} />
            <Route path='/wechat-qrcode' element={<WechatQRCode />} />
          </Routes>
        </ModalProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
