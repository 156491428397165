import { MotionProps } from 'framer-motion'

import { DEFAULT_STAGGER_PARENT } from '@/utils/base-motion'

export const CARD_MOTION: MotionProps = {
  variants: {
    hidden: { opacity: 0, scale: 0.9 },
    visible: { opacity: 1, scale: 1 },
  },
  whileHover: {
    scale: 1.05,
  },
}

export const CONTAINER_MOTION: MotionProps = DEFAULT_STAGGER_PARENT
