import Section from '@/components/Section'

const VideoIntro = () => {
  return (
    <Section className='w-full max-w-none bg-black sm:px-6 pt-0 md:pt-0'>
      <div className='max-w-6xl aspect-video mx-auto flex items-center justify-center'>
        <iframe
          className='h-full w-full'
          src='https://www.youtube.com/embed/aIf0zkLoOFI'
          title='Arund - Introduce Your Brand To The City'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        />
      </div>
    </Section>
  )
}

export default VideoIntro
