import { useFormik } from 'formik'
import { toast } from 'react-toastify'

import Button from '@/components/Button'
import InputField from '@/components/Input/InputField'
import { useModalContext } from '@/components/providers/ModalProvider'

import { CommonProps } from './types'

const Callback = ({ setStep, submitResponse }: CommonProps) => {
  const { setComponent } = useModalContext()
  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      name: '',
    },
    onSubmit: (values) => {
      submitResponse?.(values)
      toast('Thanks for completing the form. We will be in touch soon', {
        type: 'success',
        className: 'mt-16',
        autoClose: 2000,
        position: 'top-center',
      })
      setComponent(undefined)
    },
  })

  return (
    <form className='h-full max-w-xl mx-auto' onSubmit={formik.handleSubmit}>
      <div className='flex flex-col h-full'>
        <InputField
          id='name'
          required
          label='Your name'
          placeholder='Name'
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <InputField
          id='email'
          required
          label='Email'
          placeholder='Email'
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <InputField
          id='phone'
          required
          label='Phone'
          placeholder='123-456-7890'
          value={formik.values.phone}
          onChange={formik.handleChange}
        />
        <div className='mt-auto flex'>
          <Button
            className='w-1/2 mr-6 rounded-lg bg-gray-100 text-black'
            type='button'
            onClick={() => setStep((p) => p - 1)}
          >
            Back
          </Button>
          <Button className='w-1/2 rounded-lg bg-blue-500' type='submit'>
            Submit
          </Button>
        </div>
      </div>
    </form>
  )
}

export default Callback
