import driving from '@/assets/car-driving-cuate.svg';
import contactUs from '@/assets/contact-us-rafiki.svg';
import statistics from '@/assets/statistics-bro.svg';
import ListDisplay from '@/components/ListDisplay';
import Section from '@/components/Section';

const ITEMS = [
  {
    title: 'Event Planning',
    desc: "Arund's Adhoc Car Pop Up offers complete flexibility. Simply contact us for events where your target audience gathers, and we&apos;ll collaborate to maximize exposure for your business. Our event planning experts also provide a free consulting service that will locate the most suitable events across the city.",
    icon: contactUs, 
  },
  {
    title: 'Event Execution',
    desc: "Work with our team to create the most eye-catching display with our cars (a giant burrito on top of our car). On event day, our drivers will reach out for events where your target audience gathers, and we'll collaborate to maximize exposure for your business.",
    icon: driving,
  },
  {
    title: 'View Reports',
    desc: 'After the event, you will have access to an exposure heat map and report of where your advertisement has been seen, how much distance it travels, and additional performance metrics that you can utilize to measure the success of the campaign.',
    icon: statistics,
  },
]

const HowItWorks = () => {
  return (
    <Section>
      <Section.Title>How It Works</Section.Title>
      <Section.Subtitle>
        For on-demand adhoc event advertising, we bring your customized advertisement with our
        vehicles to the most popular events across the city for targeted exposures, NBA games,
        concerts, etc.
      </Section.Subtitle>

      <div className='max-w-6xl mx-auto pb-7 px-4 md:px-8'>
        <ListDisplay.TimelineForm items={ITEMS} />
      </div>
    </Section>
  )
}

export default HowItWorks
