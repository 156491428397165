import autoBg from '@/assets/auto/1.jpg'
import tiffBg from '@/assets/tiff/1.jpg'
import tkfwBg from '@/assets/tkfw/1.jpg'
import GallerySwiper from '@/components/GallerySwiper'
import Section from '@/components/Section'

const Gallery = () => {
  return (
    <Section className='w-full max-w-none bg-black px-0'>
      <Section.Title className='text-white mb-6 md:mb-12'>Our Success</Section.Title>

      <div className='relative'>
        <GallerySwiper
          views={[
            {
              title: 'Toronto International Film Festival 2023',
              description: 'Toronto International Film Festival',
              image: tiffBg,
            },
            {
              title: 'Canadian International Auto Show',
              description: 'Canadian International Autoshow',
              image: autoBg,
            },
            {
              title: 'TKFW Show',
              description: 'Toronto Kids Fashion Week (Ottawa)',
              image: tkfwBg,
            },
          ]}
        />
      </div>
    </Section>
  )
}

export default Gallery
