import header from '@/assets/tiff/1.jpg'
import Page from '@/components/Page'

import ClientPortal from './ClientPortal'
import CompetitiveAnalysis from './CompetitiveAnalysis'

const Analytics = () => {
  return (
    <Page>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>Analytics</Page.Header.Title>
        <Page.Header.Subtitle>Next generation advertisement strategy</Page.Header.Subtitle>
      </Page.Header>

      <CompetitiveAnalysis />
      <ClientPortal />
      <Page.Footer />
    </Page>
  )
}

export default Analytics
