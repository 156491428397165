import header from '@/assets/tiff/3.jpg'
import Button from '@/components/Button'
import Page from '@/components/Page'

import HowItWorks from './HowItWorks'
import Why from './Why'

const Adhoc = () => {
  return (
    <main className='flex-grow'>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>Adhoc Events</Page.Header.Title>
        <Page.Header.Subtitle>Huge engagement, ideal for event hosting</Page.Header.Subtitle>
        <Button className='text-xl text-black bg-white hover:brightness-75 py-4 px-6 rounded-full mt-10 w-auto'>
          Get In Touch
        </Button>
      </Page.Header>

      <HowItWorks />
      <Why />

      <Page.Footer />
    </main>
  )
}

export default Adhoc
