import { useEffect } from 'react'

import { useThemeContext } from '@/components/providers/ThemeProvider'
import { BaseUI } from '@/components/types'
import { useDefaultMediaQuery } from '@/hooks/useMediaQuery'
import cn from '@/utils/cn'

type Props = BaseUI & {
  bgSrc?: string
  bgVideoSrc?: string
  dark?: boolean
}

const PageHeaderComponent = ({
  bgSrc,
  bgVideoSrc,
  dark = true,
  children,
  className = 'h-[24rem] md:h-[36rem] xl:h-[48rem]',
}: Props) => {
  const { isSm } = useDefaultMediaQuery()
  const { setTransparentHeader } = useThemeContext()
  const hasOverlay = !!bgSrc || !!bgVideoSrc
  const showImg = !isSm ? true : bgVideoSrc ? false : !!bgSrc
  const isHeaderTransparent = dark || hasOverlay

  useEffect(() => {
    setTransparentHeader(isHeaderTransparent)
  }, [isHeaderTransparent, setTransparentHeader])

  return (
    <div
      className={cn(
        'relative flex flex-col items-center justify-center overflow-clip max-h-[60rem]',
        className,
      )}
    >
      {showImg && <img className='h-full w-full object-cover' src={bgSrc} />}

      {!!bgVideoSrc && !showImg && (
        <video
          className='absolute left-0 top-0 h-full w-full object-cover'
          src={bgVideoSrc}
          loop
          autoPlay
          playsInline
          muted
        />
      )}

      <div className='z-10 absolute top-0 left-0 bottom-0 right-0 flex flex-col justify-center items-center px-4'>
        {children}
      </div>

      {hasOverlay && (
        <div className='absolute top-0 left-0 bottom-0 right-0 backdrop-brightness-90 bg-gradient-to-b from-transparent to-black' />
      )}
    </div>
  )
}

const HeaderTitle = ({ className, children }: BaseUI) => (
  <h1 className={cn('text-4xl md:text-7xl font-bold text-white mb-4 text-center', className)}>
    {children}
  </h1>
)

const HeaderSubtitle = ({ className, children }: BaseUI) => (
  <h1 className={cn('text-xl md:text-2xl text-gray-200 text-center', className)}>{children}</h1>
)

const PageHeader = Object.assign(PageHeaderComponent, {
  Title: HeaderTitle,
  Subtitle: HeaderSubtitle,
})

export default PageHeader
