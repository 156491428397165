import sticker1 from '@/assets/tiff/1.jpg'
import sticker2 from '@/assets/tiff/2.jpg'
import sticker3 from '@/assets/tiff/3.jpg'
import sticker4 from '@/assets/tiff/4.jpg'
import sticker5 from '@/assets/tiff/5.jpg'
import sticker6 from '@/assets/tiff/6.jpg'
import sticker7 from '@/assets/tiff/7.jpg'
import sticker8 from '@/assets/tiff/8.jpg'
import Section from '@/components/Section'

import LazyView from './LazyView'
const IMAGES = [sticker1, sticker2, sticker3, sticker4, sticker5, sticker6, sticker7, sticker8]

const Tiff = () => {
  return (
    <Section className='w-full max-w-none bg-black'>
      <Section.Title className='text-white'>Toronto International Film Festival</Section.Title>
      <Section.Subtitle className='!text-gray-200'>Our success story</Section.Subtitle>

      <div className='py-4 md:py-8'>
        <div className='max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2'>
          <LazyView images={IMAGES} />
        </div>
      </div>

      <div className='max-w-6xl aspect-video mx-auto flex items-center justify-center'>
        <iframe
          className='h-full w-full'
          src='https://www.youtube.com/embed/0-n9dePTZIQ'
          title='Arund - Introduce Your Brand To The City'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowFullScreen
        />
      </div>
    </Section>
  )
}

export default Tiff
