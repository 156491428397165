import { InputHTMLAttributes } from 'react'

import cn from '@/utils/cn'

type Props = {
  label?: string
  labelClassName?: string
  inputClassName?: string
  isTextArea?: boolean
} & InputHTMLAttributes<HTMLInputElement> &
  InputHTMLAttributes<HTMLTextAreaElement>

const InputField = ({
  label,
  labelClassName,
  inputClassName,
  isTextArea,
  id,
  type,
  value,
  placeholder,
  required,
  onChange,
}: Props) => {
  return (
    <>
      <label
        className={cn('block text-gray-800 text-sm font-medium mb-1 mt-2', labelClassName, {
          labelRequired: required,
        })}
        htmlFor={id}
      >
        {label}
      </label>
      {isTextArea ? (
        <textarea
          id={id}
          value={value}
          onChange={onChange}
          className={cn(
            'form-input w-full text-gray-800 border-gray-300 rounded-md h-32 resize-none',
            inputClassName,
          )}
          placeholder={placeholder}
          required={required}
        />
      ) : (
        <input
          id={id}
          type={type}
          value={value}
          onChange={onChange}
          className={cn(
            'form-input w-full text-gray-800 border-gray-300 rounded-md',
            inputClassName,
          )}
          placeholder={placeholder}
          required={required}
        />
      )}
    </>
  )
}

export default InputField
