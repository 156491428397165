import { motion } from 'framer-motion'

import { CARD_MOTION, CONTAINER_MOTION } from './motion'
import { ItemProps, ListDisplayProps } from './type'

const Card = ({ index, icon, title, desc }: ItemProps) => {
  return (
    <motion.div
      {...CARD_MOTION}
      className='flex flex-col p-4 md:p-6 items-start justify-start md:flex-row'
    >
      <div className='flex flex-col justify-center'>
        {index !== undefined && (
          <div className='w-10 leading-10 mb-4 text-center text-xl text-white rounded-full bg-yellow-600'>
            {index + 1}
          </div>
        )}
        <div className='mb-2 text-3xl font-bold'>{title}</div>
        <p className='text-gray-600 text-xl'>{desc}</p>
      </div>

      {typeof icon === 'string' ? <img className='w-60 h-60 mx-4' src={icon} /> : icon}
    </motion.div>
  )
}

const DetailForm = ({ items, cols = 2, numbered = false }: ListDisplayProps) => {
  return (
    <motion.div {...CONTAINER_MOTION} className={`grid grid-cols-1 md:grid-cols-${cols} md:gap-8`}>
      {items.map((item, i) => (
        <Card key={`item-${i}`} {...item} index={numbered ? i : undefined} />
      ))}
    </motion.div>
  )
}

export default DetailForm
