import MarkdownView from '@/components/MarkdownView'
import Page from '@/components/Page'
import Section from '@/components/Section'

import md from './WhySCA.md'

const WhySCA = () => {
  return (
    <Page>
      <Section>
        <MarkdownView src={md} />
      </Section>
      <Page.Footer />
    </Page>
  )
}

export default WhySCA
