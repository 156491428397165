import sticker1 from '@/assets/tkfw/1.jpg'
import sticker2 from '@/assets/tkfw/2.jpg'
import sticker3 from '@/assets/tkfw/3.jpg'
import sticker4 from '@/assets/tkfw/4.jpg'
import sticker5 from '@/assets/tkfw/5.jpg'
import sticker6 from '@/assets/tkfw/6.jpg'
import Section from '@/components/Section'

import LazyView from './LazyView'
const IMAGES = [sticker1, sticker2, sticker3, sticker4, sticker5, sticker6]

const TkfwOttawa = () => {
  return (
    <Section className='w-full max-w-none bg-black'>
      <Section.Title className='text-white'>Toronto Kids Fashion Week (Ottawa)</Section.Title>
      <Section.Subtitle className='!text-gray-200'>Our success story</Section.Subtitle>

      <div className='py-4 md:py-8'>
        <div className='max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2'>
          <LazyView images={IMAGES} />
        </div>
      </div>
    </Section>
  )
}

export default TkfwOttawa
