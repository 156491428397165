import { motion } from 'framer-motion'

import { isNil } from '@/utils/notlodash'
import { gridColsMd } from '@/utils/tw-class'

import { CARD_MOTION, CONTAINER_MOTION } from './motion'
import { ItemProps, ListDisplayProps } from './type'

const Card = ({ title, desc, icon, index }: ItemProps) => {
  if (isNil(index)) {
    throw new Error('index must be provided for ZigZag style')
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isLeft = index! % 2 === 0

  return (
    <motion.div
      {...CARD_MOTION}
      className={
        'flex flex-col ' + (isLeft ? 'md:flex-row' : 'md:flex-row-reverse') + ' items-center'
      }
    >
      {typeof icon === 'string' ? <img className='w-3/5 px-2 md:w-1/3' src={icon} /> : icon}

      <div className='basis-2/3 flex flex-col items-center px-2'>
        <div className='text-3xl font-bold text-center py-5'>{title}</div>
        <p className='text-gray-600 text-lg text-center'>{desc}</p>
      </div>
    </motion.div>
  )
}

const ZigZagForm = ({ items, cols = 1 }: ListDisplayProps) => {
  return (
    <motion.div {...CONTAINER_MOTION} className={`grid grid-cols-1 ${gridColsMd(cols)}`}>
      {items.map((item, i) => (
        <Card key={`item-${i}`} {...item} index={i} />
      ))}
    </motion.div>
  )
}

export default ZigZagForm
