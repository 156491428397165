import driving from '@/assets/car-driving-cuate.svg'
import Section from '@/components/Section'

const points = [
  {
    icon: driving,
    title: 'Diver Selection',
    desc: (
      <div className='pt-4'>
        <p>We use AI to select the drivers for your advertisement based on</p>
        <ul className='list-disc list-inside p-4 md:p-6'>
          <li>Distance </li>
          <li>Make and model of the car</li>
          <li>where they drive</li>
        </ul>
      </div>
    ),
  },
]

const How = () => {
  return (
    <Section>
      <Section.Title>How We Use AI?</Section.Title>
      <Section.Subtitle>Learn how we use AI at Arund</Section.Subtitle>
    </Section>
  )
}

export default How
