import contactUs from '@/assets/contact-us-rafiki.svg'
import cn from '@/utils/cn'

type Job = {
  title: string
  description: string
  type: string
}

const JobItem = ({ title, description, type }: Job) => {
  let tagColor
  if (type === 'Sales') tagColor = 'bg-blue-700'
  else if (type === 'Engineering') tagColor = ' bg-orange-700'

  return (
    <div className='relative flex flex-col p-4 items-start bg-white rounded mb-8 md:p-8 md:shadow-xl'>
      <div className='text-2xl text-start font-bold mb-2'>{title}</div>
      <div className='text-md text-start text-gray-600'>{description}</div>
      <div className={cn('absolute top-2 right-4 p-2 text-white rounded-full', tagColor)}>
        {type}
      </div>
    </div>
  )
}

const Career = () => {
  return (
    <main className='flex-grow'>
      <div className='bg-arund pt-20 h-96 flex flex-col items-center justify-center px-4 md:px-16'>
        <h1 className='text-4xl md:text-7xl font-bold mb-4 text-white text-center'>Join Us</h1>
        <p className='text-xl text-gray-100 text-center'>Want to be part of the team?</p>
      </div>

      <div className='max-w-6xl mx-auto text-center px-4 my-8 md:my-16 md:px-8'>
        <h2 className='mb-4 text-3xl md:text-5xl font-bold'>What Are We</h2>

        <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
          <p className='text-xl text-gray-600 my-auto text-start leading-relaxed'>
            {
              'Arund treats every employee sincerely, regardless of background, age, or gender. We welcome those who truly possess professional expertise, a strong work ethic, and rich imagination!'
            }
            <br />
            <br />
            {
              'We firmly believe that talent is the most crucial factor for business success! We seek the best individuals, making every effort to provide better resources and services for each Arund member, aiding in realizing their maximum potential in life!'
            }
          </p>
          <img src={contactUs} />
        </div>
      </div>

      <div className='max-w-6xl mx-auto text-center px-2 my-8 md:my-16 md:px-8'>
        <h2 className='mb-4 text-3xl md:text-5xl font-bold'>Open Positions</h2>
        <div className='text-xl text-gray-600 text-center mx-auto leading-relaxed'>
          View our open positions across sales, operation, and engineering. To apply, send us an
          email at{' '}
          <a className='font-bold underline' href='mailto: info@arund.ca'>
            info@arund.ca
          </a>
        </div>
        <div className='relative my-6'>
          <a
            className='text-2xl px-4 py-3 rounded-full font-semibold hover:brightness-75 transition-all duration-500 text-white bg-arund'
            href='mailto: info@arund.ca'
          >
            Apply
          </a>
        </div>

        <JobItem
          title='Business Development Representative'
          type='Sales'
          description='The ideal candidate will play a crucial role in identifying potential business opportunities, establishing connections, and nurturing relationships to drive company growth.'
        />
        <JobItem
          title='Software Engineer'
          type='Engineering'
          description="You will be responsible for designing, developing, and maintaining high-quality software applications that align with our company's goals and meet user needs."
        />
      </div>
    </main>
  )
}

export default Career
