import { IoCheckmark } from 'react-icons/io5'

import cn from '@/utils/cn'

import { StepperProps } from './types'

const Horizontal = ({ activeStep, steps }: StepperProps) => {
  return (
    <div className='flex flex-col w-full h-full'>
      <ul className='flex items-center justify-around w-full'>
        {steps.map((step, i) => (
          <li
            key={`${step.title}-${i}`}
            className={cn('flex items-center space-x-3 ', {
              'text-blue-600': i === activeStep,
            })}
          >
            <span
              className={cn(
                'flex items-center justify-center w-8 h-8 border-2 rounded-full',
                { 'border-blue-600': i === activeStep },
                { 'border-green-500': i < activeStep },
              )}
            >
              {i < activeStep ? <IoCheckmark size={22} className='text-green-500' /> : i + 1}
            </span>
            <div className='font-medium leading-tight'>{step.title}</div>
          </li>
        ))}
      </ul>

      {/* We don't destroy the tree, we just set it to invisible so form content is not destroyed */}
      {steps.map((step, i) => (
        <div key={`${step.title}-${i}`} className={cn('mt-6 flex-1', { hidden: i !== activeStep })}>
          {step.content}
        </div>
      ))}
    </div>
  )
}

export default Horizontal
