import { BaseUI } from '@/components/types'
import cn from '@/utils/cn'

import PageHeader from './PageHeader'

const PageComponent = ({ children, className }: BaseUI) => (
  <main className={cn('flex-grow', className)}>{children}</main>
)

const PageFooter = () => <div className='h-8 md:h-20' />

const Page = Object.assign(PageComponent, {
  Header: PageHeader,
  Footer: PageFooter,
})

export default Page
