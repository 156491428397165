import Page from '@/components/Page'
import LearnMore from '@/sections/LearnMore'
import Research from '@/sections/Research'

import AboutArund from './AboutArund'
import CustomerSelector from './CustomerSelector'
import DataDriven from './DataDriven'
import Gallery from './Gallery'
import Hero from './Hero'
import StatsIntro from './StatsIntro'
import VideoIntro from './VideoIntro'
import Where from './Where'

const Home = () => {
  return (
    <Page>
      <Hero />
      <StatsIntro />
      <CustomerSelector />
      <AboutArund />
      <Where />
      <Gallery />
      <VideoIntro />
      <Research />
      <LearnMore />
      <DataDriven />
      <Page.Footer />
    </Page>
  )
}

export default Home
