import header from '@/assets/auto/1.jpg'
import Page from '@/components/Page'

import How from './How'

const AI = () => {
  return (
    <Page>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>Artificial Intelligence</Page.Header.Title>
        <Page.Header.Subtitle>
          AI-powered technology to help maximize advertisement performance
        </Page.Header.Subtitle>
      </Page.Header>

      <How />

      <Page.Footer />
    </Page>
  )
}

export default AI
