import Accordion from '@/components/Accordion'

const FAQ = () => {
  return (
    <div className='max-w-6xl mx-auto p-4 flex flex-col gap-4'>
      <Accordion
        title='How much money will I make?'
        content='Compensation entirely based upon the size of the advertisement, miles driven, location and duration. Most of our drivers can earn from $50 to up to $300 per month. Certain models are eligible for up to $500 compensation'
      />
      <Accordion
        title='What is the size and material of the Ad sticker?'
        content="The standard size of our Ad sticker will be about 15cm*40cm. However, larger size will also be available for additional compensation depending on advertisers' needs. The one-time Ad sticker is made of high-quality, waterproof PVC and will not cause any damage to your car paint."
      />
      <Accordion
        title='What are the requirements to become an Arund Driver?'
        content={
          <ol className='list-disc ml-4'>
            <li>Driver must be at least 18 years or older and have a valid driver license</li>
            <li>Pass a basic background check</li>
            <li>Have a clean driving record</li>
          </ol>
        }
      />
      <Accordion
        title='What type of car is eligible?'
        content='All types of vehicles are welcome. To be eligible for the advertisement run, your vehicle should be no more than 15 years old and in good condition.'
      />
      <Accordion
        title='How often are offers available?'
        content="Wait time entirely depends on our advertisers client's need (when and where) and could vary from days to months. We will notify you through email and text messages whenever a new offer is available, and thank you for your attention."
      />
      <Accordion
        title='Can I sign multiple contracts at the same time?'
        content='To maximize the impression of our advertisement, you may not sign multiple contracts at the same time.'
      />
      <Accordion
        title='How do I get paid?'
        content='Reimbursement will be paid at the end of each of the contract period through direct deposit to your bank account.'
      />
      <Accordion
        title='What if I removed the sticker before my contract ends?'
        content='The Ad stickers are one-time stickers and cannot be reused. If you accidentally removed your sticker before your contract ends but still wish to continue the contract, please contact our customer service. We will ship you a new sticker for a cost of $15.'
      />
    </div>
  )
}

export default FAQ
