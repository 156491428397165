import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'

import cn from '@/utils/cn'

import { containerMotion, contentMotion, iconMotion } from './motion'

type Props = {
  title: ReactNode
  content: ReactNode
  titleClassName?: string
  iconSize?: number
}

const Accordion = ({ title, content, titleClassName, iconSize = 8 }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <motion.div
      {...containerMotion}
      animate={isOpen ? 'dark' : 'light'}
      className={cn('overflow-hidden mx-auto w-full rounded-lg')}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className='p-8 flex text-start items-center w-full focus:outline-none cursor-pointer'>
        <div className={cn('text-2xl', titleClassName)}>{title}</div>
        <motion.div
          {...iconMotion}
          animate={isOpen ? 'open' : 'closed'}
          className='flex-shrink-0 ml-auto'
        >
          <FiChevronDown className={`w-${iconSize} h-${iconSize}`} />
        </motion.div>
      </div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div {...contentMotion} className='text-gray-300 text-xl'>
            <div className={'px-8 pb-8'}>{content}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

export default Accordion
