import { motion } from 'framer-motion'
import { ReactNode } from 'react'
import {
  BsCarFront,
  BsCurrencyDollar,
  BsEmojiSmile,
  BsHandThumbsUp,
  BsLightbulb,
  BsPeople,
  BsSearchHeart,
  BsShop,
} from 'react-icons/bs'

import Section from '@/components/Section'
import { DEFAULT_STAGGER_CHILD, DEFAULT_STAGGER_PARENT } from '@/utils/base-motion'

type CardProps = {
  percentage: number
  text: ReactNode
  icon: ReactNode
}

const StatisticCard = ({ icon, percentage, text }: CardProps) => {
  return (
    <motion.div {...DEFAULT_STAGGER_CHILD} className='flex flex-col items-center'>
      <div>{icon}</div>
      <div className='font-bold text-4xl md:text-6xl'>{`${percentage}%`}</div>
      <div className='text-lg text-center'>{text}</div>
    </motion.div>
  )
}

const ICON_SIZE = 60

const Research = () => {
  return (
    <Section>
      <Section.Title>Research & Demographic</Section.Title>
      <Section.Subtitle>Outdoor advertisement effectiveness and perception</Section.Subtitle>

      <motion.div
        {...DEFAULT_STAGGER_PARENT}
        className='grid grid-cols-1 gap-8 my-8 md:my-20 md:grid-cols-2 lg:grid-cols-4'
      >
        <StatisticCard
          icon={<BsPeople size={ICON_SIZE} />}
          percentage={45}
          text={
            <>
              of the Residents <b>frequently notice</b> car advertising in their local area
            </>
          }
        />
        <StatisticCard
          icon={<BsLightbulb size={ICON_SIZE} />}
          percentage={43}
          text={
            <>
              of the Residents have <b>taken actions</b> after seeing car advertising
            </>
          }
        />
        <StatisticCard
          icon={<BsHandThumbsUp size={ICON_SIZE} />}
          percentage={68}
          text={
            <>
              of the Residents have more <b>positive impression</b> of car advertising than all
              other types of media
            </>
          }
        />
        <StatisticCard
          icon={<BsCarFront size={ICON_SIZE} />}
          percentage={68}
          text={
            <>
              of the Residents are <b>more likely</b> to spot exterior ads during daily commuting
            </>
          }
        />
        <StatisticCard
          icon={<BsSearchHeart size={ICON_SIZE} />}
          percentage={25}
          text={
            <>
              <b>Look and read</b> information on car ads and interact with the mobile app
            </>
          }
        />
        <StatisticCard
          icon={<BsEmojiSmile size={ICON_SIZE} />}
          percentage={19}
          text={
            <>
              <b>Share</b> the information with family and friends
            </>
          }
        />
        <StatisticCard
          icon={<BsShop size={ICON_SIZE} />}
          percentage={11}
          text={
            <>
              <b>Visit</b> the physical retail/business location after seeing the ads
            </>
          }
        />
        <StatisticCard
          icon={<BsCurrencyDollar size={ICON_SIZE} />}
          percentage={10}
          text={
            <>
              of the Residents <b>lead to successful conversion</b>
            </>
          }
        />
      </motion.div>
    </Section>
  )
}

export default Research
