import { FaCheckCircle, FaThumbsDown, FaThumbsUp, FaTimesCircle } from 'react-icons/fa'

import Section from '@/components/Section'

const DATA = [
  [
    'Shared Car',
    <>
      Medium Size
      <br />
      Good Quantity
    </>,
    <>
      Close <br />
      Long Time
    </>,
    <>
      <FaThumbsUp size={20} className='mx-1' />
      <FaThumbsUp size={20} className='mx-1' />
    </>,
    <>
      Good
      <br /> Flexible
    </>,
    <>
      Dynamic
      <br />
      Door to Door
    </>,
    <>
      <FaCheckCircle size={20} className='text-green-600' />
    </>,
  ],
  [
    'Bus',
    <>
      Big Size
      <br />
      Limited Amount
    </>,
    <>
      Close <br />
      Long Time
    </>,
    <>
      <FaThumbsUp size={20} className='mx-1' />
      <FaThumbsUp size={20} className='mx-1' />
    </>,
    <>
      Expensive
      <br /> Fixed
    </>,
    'Dynamic',
    <>
      <FaTimesCircle size={20} className='text-red-600' />
    </>,
  ],
  [
    'Bus Shelter',
    <>
      Medium Size
      <br /> Good Quantity
    </>,
    <>
      Medium
      <br />
      Short Time
    </>,
    <>
      <FaThumbsUp size={20} className='mx-1' />
    </>,
    <>
      Expensive
      <br /> Fixed
    </>,
    'Static',
    <>
      <FaTimesCircle size={20} className='text-red-600' />
    </>,
  ],
  [
    'Billboard',
    <>
      Biggest Size
      <br />
      Limited Quantity
    </>,
    <>
      Far
      <br />
      Short Time
    </>,
    <>
      <FaThumbsUp size={20} className='mx-1' />
      <FaThumbsUp size={20} className='mx-1' />
      <FaThumbsUp size={20} className='mx-1' />
    </>,
    'Most Expensive',
    'Static',
    <>
      <FaTimesCircle size={20} className='text-red-600' />
    </>,
  ],
  [
    'Trash Can',
    <>
      Medium Size
      <br /> Good Quantity
    </>,
    <>
      Medium
      <br />
      Short Time
    </>,
    <>
      <FaThumbsDown size={20} className='mx-1' />
    </>,
    <>
      Good
      <br /> Fixed
    </>,
    'Static',
    <>
      <FaTimesCircle size={20} className='text-red-600' />
    </>,
  ],
]

const CompetitiveAnalysis = () => {
  return (
    <Section>
      <Section.Title>Competitive Analysis</Section.Title>

      <div className='overflow-x-scroll py-4 md:py-8'>
        <table className='min-w-[1000px] bg-white border border-gray-300 mx-auto'>
          <thead>
            <tr>
              <th className='py-2 px-4 border-b'>Category</th>
              <th className='py-2 px-4 border-b'>Size & Quantity</th>
              <th className='py-2 px-4 border-b'>Natural Distance</th>
              <th className='py-2 px-4 border-b'>Brand Image</th>
              <th className='py-2 px-4 border-b'>Price & Flexibility</th>
              <th className='py-2 px-4 border-b'>Mobility</th>
              <th className='py-2 px-4 border-b'>Personal Effect</th>
            </tr>
          </thead>
          <tbody>
            {DATA.map((row, i) => (
              <tr key={i}>
                {row.map((col, i) => (
                  <td key={i} className='py-2 px-4 border-b items-center'>
                    <div className='flex items-center justify-center text-center'>{col}</div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Section>
  )
}

export default CompetitiveAnalysis
