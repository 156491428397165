import driving from '@/assets/car-driving-cuate.svg'
import contactUs from '@/assets/contact-us-rafiki.svg'
import statistics from '@/assets/statistics-bro.svg'
import ListDisplay from '@/components/ListDisplay'
import Section from '@/components/Section'

const ITEMS = [
  {
    title: 'Create a Campaign',
    desc: 'Contact us to create a campaign. We will work with you and take care of the sticker design and making, campaign duration and start date. You can also pick where the advertisement is mostly seen and we will match the drivers for you.',
    icon: contactUs,
  },
  {
    title: 'Start Campaign',
    desc: 'On the start date, we will make sure the drivers have the stickers attached to their car so they can start driving with your campaign for the duration you expect.',
    icon: driving,
  },
  {
    title: 'View Reports',
    desc: 'We will send you check-in updates throughout the duration of the campaign. At the end of the campaign, you will have access to an exposure heat map and report of where your advertisement has been seen, and how much distance it has traveled.',
    icon: statistics,
  },
]

const HowItWorks = () => {
  return (
    <Section>
      <Section.Title>How It Works</Section.Title>
      <Section.Subtitle>
        For a shared car advertising campaign, we put your advertisement posters on sides of our
        registered vehicles and charge by duration of the campaign.
      </Section.Subtitle>

      <div className='max-w-6xl mx-auto pb-7 px-4 md:px-8'>
        <ListDisplay.TimelineForm items={ITEMS} />
      </div>
    </Section>
  )
}

export default HowItWorks
