import { useCallback, useState } from 'react'

import { AdvertiserAPIParams, putAdvertiser } from '@/api'
import Stepper from '@/components/Stepper'

import Budget from './Budget'
import Callback from './Callback'
import Info from './Info'

const BookDemo = () => {
  const [step, setStep] = useState(0)
  const [, setResponse] = useState<AdvertiserAPIParams>()

  const recordResponse = useCallback((v: Partial<AdvertiserAPIParams>) => {
    setResponse((prev) => ({ ...prev, ...v } as AdvertiserAPIParams))
  }, [])

  const recordAndSubmitResponse = useCallback((v: Partial<AdvertiserAPIParams>) => {
    setResponse((prev) => {
      const response = { ...prev, ...v } as AdvertiserAPIParams
      response && putAdvertiser(response)
      return response
    })
  }, [])

  const steps = [
    { title: 'Basic Info', content: <Info recordResponse={recordResponse} setStep={setStep} /> },
    { title: 'Budget', content: <Budget recordResponse={recordResponse} setStep={setStep} /> },
    {
      title: 'Contact',
      content: (
        <Callback
          recordResponse={recordResponse}
          setStep={setStep}
          submitResponse={recordAndSubmitResponse}
        />
      ),
    },
  ]

  return <Stepper type='horizontal' activeStep={step} onStepChange={setStep} steps={steps} />
}

export default BookDemo
