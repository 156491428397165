import { motion } from 'framer-motion'

import portal1 from '@/assets/portal/1.jpg'
import portal2 from '@/assets/portal/2.jpg'
import portal3 from '@/assets/portal/3.jpg'
import Section from '@/components/Section'
import { useDefaultMediaQuery } from '@/hooks/useMediaQuery'

import { imageMotion } from './motion'

const ClientPortalDesktop = () => {
  return (
    <Section className='max-w-7xl'>
      <div className='flex'>
        <div className='sticky top-20 h-screen flex flex-1'>
          <div className='my-auto mx-16'>
            <Section.Title>Track Progress</Section.Title>
            <Section.Subtitle>
              With Arund Client Portal, you can track advertisement progress with ease
            </Section.Subtitle>
          </div>
        </div>

        <div>
          <motion.img src={portal1} {...imageMotion} />
          <motion.img src={portal2} {...imageMotion} />
          <motion.img src={portal3} {...imageMotion} />
        </div>
      </div>
    </Section>
  )
}

const ClientPortalMobile = () => {
  return (
    <Section>
      <Section.Title>Track Progress</Section.Title>
      <Section.Subtitle>
        With Arund Client Portal, you can track advertisement progress with ease
      </Section.Subtitle>

      <div className='w-screen -m-4'>
        <img src={portal1} />
        <img src={portal2} />
        <img src={portal3} />
      </div>
    </Section>
  )
}

const ClientPortal = () => {
  const { isMd } = useDefaultMediaQuery()
  return isMd ? <ClientPortalDesktop /> : <ClientPortalMobile />
}

export default ClientPortal
