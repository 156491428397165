import { FaCarSide } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

type Props = {
  link: string
  title: string
  description: string
}

const PageLink = ({ link, title, description }: Props) => {
  const navigate = useNavigate()
  return (
    <div
      className='flex items-center my-4 cursor-pointer hover:font-semibold'
      onClick={() => navigate(link)}
    >
      <FaCarSide className='flex-shrink-0 w-4 h-4 hover:text-arund mr-4' />
      <div className='flex flex-col'>
        <span className='text-sm'>{title}</span>
        <span className='text-xs text-gray-600'>{description}</span>
      </div>
    </div>
  )
}

export default PageLink
