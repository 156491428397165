import { motion } from 'framer-motion';

import { isNil } from '@/utils/notlodash';
import { gridColsMd } from '@/utils/tw-class';

import { CARD_MOTION, CONTAINER_MOTION } from './motion';
import { ItemProps, ListDisplayProps } from './type';

// Timeline component to render the vertical line, visible only on medium screens and above
const Timeline = ({ steps = 5 }: { steps?: number }) => {
  // Define animation variants for the entire timeline container
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2, // Time between animations of each child (dots)
      },
    },
  };

  // Define animation variants for each dot
  const dotVariants = {
    hidden: { scale: 0, opacity: 0 },
    visible: { scale: 1, opacity: 1, transition: { duration: 0.4 } },
  };

  // Define animation variants for the line
  const lineVariants = {
    hidden: { height: 0 },
    visible: { height: '100%', transition: { duration: 1.5 } }, // Adjust duration as needed
  };

  return (
    <motion.div
      className="hidden md:block absolute left-1/2 transform -translate-x-1/2 w-px h-full"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <motion.div
        className="absolute left-1/2 transform -translate-x-1/2 w-px bg-gradient-to-b from-blue-500 to-blue-300"
        variants={lineVariants}
      />

      <div className="relative h-full flex flex-col items-center justify-around">
        {[...Array(steps)].map((_, index) => (
          <motion.div
            key={index}
            className="w-4 h-4 bg-white border-2 border-blue-500 rounded-full"
            style={{
              marginTop: index === 0 ? '0' : 'auto',
              marginBottom: index === steps - 1 ? '0' : 'auto',
            }}
            variants={dotVariants}
          />
        ))}
      </div>
    </motion.div>
  );
};


// StepIcon component for the step icon on each card
const StepIcon = ({ index }: { index: number }) => (
  <div className="flex-shrink-0 w-12 h-12 md:w-12 md:h-12 bg-blue-500 text-white rounded-full flex items-center justify-center text-xl mr-4">
    {index + 1}
  </div>
);

const Card = ({ title, desc, icon, index }: ItemProps) => {
  if (isNil(index)) {
    throw new Error('index must be provided for Timeline style');
  }
  const safeIndex = index ?? 0;

  return (
    <motion.div
      {...CARD_MOTION}
      className={`relative mb-10 w-full p-4 md:w-1/2 ${safeIndex % 2 === 0 ? 'md:mr-auto' : 'md:ml-auto'} md:-mt-40`}
    >
      <div className="flex items-center md:items-center">
        <div className="flex-grow">
          <StepIcon index={safeIndex} />
          <div className="text-2xl md:text-3xl font-bold py-2">{title}</div>
          <p className="text-gray-600 text-base md:text-lg">{desc}</p>
          {typeof icon === 'string' ? (
            <img className="w-full h-auto md:w-80 md:h-80 mt-4" src={icon} alt="" />
          ) : (
            icon
          )}
        </div>
      </div>
    </motion.div>
  );
};

const TimeLineForm = ({ items, cols = 1 }: ListDisplayProps) => {
  return (
    <motion.div
      {...CONTAINER_MOTION}
      className={`relative grid grid-cols-1 ${gridColsMd(cols)} pt-10 md:pt-20 mt-20 md:mt-40`}
    >
      <Timeline steps={items.length} /> {/* Pass the length of items to the Timeline */}
      {items.map((item, i) => (
        <Card key={`item-${i}`} {...item} index={i} />
      ))}
    </motion.div>
  );
};

export default TimeLineForm;
