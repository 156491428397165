import DetailForm from './DetailForm'
import SimpleForm from './SimpleForm'
import TimelineForm from './TimeLineForm'
import ZigZagForm from './ZigZagForm'

const ListDisplay = {
  SimpleForm,
  DetailForm,
  ZigZagForm,
  TimelineForm,
}

export default ListDisplay
