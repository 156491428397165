import { animate, motion, useInView, useMotionValue, useTransform } from 'framer-motion'
import { ReactNode, useEffect, useRef } from 'react'

import cn from '@/utils/cn'

type Props = {
  className?: string
  from: number
  to: number
  desc?: ReactNode
  delay?: number
  duration?: number
  transform: (i: number) => string
}
const Stats = ({ className, from, to, desc, delay = 1, duration = 2, transform }: Props) => {
  const ref = useRef(null)
  const isInView = useInView(ref)
  const count = useMotionValue(from)
  const displayValue = useTransform(count, (i) => transform(i))

  useEffect(() => {
    if (isInView) {
      const controls = animate(count, to, { duration, delay })
      return () => controls.stop()
    }
  }, [count, delay, duration, isInView, to])

  return (
    <div className='flex flex-col justify-center items-center'>
      <motion.div ref={ref} className={cn('text-4xl font-extrabold md:text-6xl', className)}>
        {displayValue}
      </motion.div>

      {desc && <div className={cn('text-lg', className)}>{desc}</div>}
    </div>
  )
}

export default Stats
