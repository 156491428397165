import { FiDollarSign } from 'react-icons/fi'
import { IoCheckmarkCircleSharp } from 'react-icons/io5'

import ListDisplay from '@/components/ListDisplay'
import Section from '@/components/Section'

const ITEMS = [
  {
    icon: <FiDollarSign className='text-white' />,
    title: 'Cost Effective',
    desc: (
      <>
        <div>Strong eye catcher and wave maker around the event</div>
        <div>Pulling and forcing to capture attention at the event</div>
      </>
    ),
  },
  {
    icon: <IoCheckmarkCircleSharp className='text-white' />,
    title: 'Unparalleled Visibility',
    desc: (
      <>
        <div>Enhance active engagement</div>
        <div>Exclusive and explosive</div>
        <div>Flexible and minimize marginal cost waste</div>
      </>
    ),
  },
  {
    icon: <IoCheckmarkCircleSharp className='text-white' />,
    title: 'Immersive Brand Experience',
    desc: (
      <>
        <div>Ignite Event & Brand Awareness</div>
        <div>Widely Publicizing</div>
      </>
    ),
  },
]

const Why = () => {
  return (
    <Section>
      <Section.Title>Benefits</Section.Title>
      <Section.Subtitle>Pop up advertisement around a popular event</Section.Subtitle>

      <ListDisplay.SimpleForm items={ITEMS} cols={2} />
    </Section>
  )
}

export default Why
