import driver from '@/assets/by-my-car-bro.svg'

const OurDriver = () => {
  return (
    <div className='relative max-w-6xl mx-auto px-4 sm:px-6'>
      <div className='max-w-3xl mx-auto text-center mt-4 md:mt-10'>
        <h1 className='text-4xl md:text-6xl font-bold py-8 md:py-8'>Our Drivers</h1>
        <div className='text-xl text-gray-600'>
          AI-enabled matching system to reach the targeted audience.
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2'>
        <div className='pb-12 w-full md:pb-0'>
          <img className='max-h-full md:max-h-96 w-full md:w-auto object-cover' src={driver} />
        </div>
        <div className='flex flex-col justify-center'>
          <p className='mb-4 text-lg text-gray-700'>
            By using our powerful internal smart software database, we are able to match client
            needs with the ideal drivers by considering factors such as location, age, gender,
            mileage, car model, profession,and more, which enable more precise and efficient
            targeting.
          </p>
        </div>
      </div>
    </div>
  )
}

export default OurDriver
