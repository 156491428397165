import { MotionProps } from 'framer-motion'

import { DEFAULT_TRANSITION } from '@/utils/base-motion'

export const imageMotion: MotionProps = {
  whileInView: {
    opacity: 1,
    translateX: 0,
  },
  initial: {
    opacity: 0,
    translateX: 100,
  },
  viewport: { once: true },
  transition: DEFAULT_TRANSITION,
}
