import { motion } from 'framer-motion'

import cn from '@/utils/cn'

import { BaseUI } from '../types'

const DURATION = 0.25
const STAGGER = 0.025

type Props = BaseUI & {
  animate?: boolean
}

const FlipText = ({ children, className, animate }: Props) => {
  if (!(typeof children === 'string')) {
    return null
  }

  return (
    <motion.div
      initial='initial'
      animate={animate ? 'hovered' : 'initial'}
      whileHover={animate ? 'initial' : 'hovered'}
      className={cn('relative block overflow-hidden whitespace-nowrap', className)}
    >
      <div>
        {children.split('').map((l, i) => (
          <motion.span
            variants={{
              initial: { y: 0 },
              hovered: { y: '-100%' },
            }}
            transition={{
              duration: DURATION,
              ease: 'easeInOut',
              delay: STAGGER * i,
            }}
            className='inline-block whitespace-pre py-2'
            key={i}
          >
            {l}
          </motion.span>
        ))}
      </div>
      <div className='absolute inset-0'>
        {children.split('').map((l, i) => (
          <motion.span
            variants={{
              initial: { y: '100%' },
              hovered: { y: 0 },
            }}
            transition={{
              duration: DURATION,
              ease: 'easeInOut',
              delay: STAGGER * i,
            }}
            className='inline-block whitespace-pre py-2'
            key={i}
          >
            {l}
          </motion.span>
        ))}
      </div>
    </motion.div>
  )
}

export default FlipText
