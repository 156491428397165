import { ReactComponent as Logo } from '@/assets/logo.svg'
import headerImg from '@/assets/tiff/8.jpg'
import header from '@/assets/tiff/arund.mp4'
import Anim from '@/components/Anim'
import BookDemo from '@/components/BookDemo'
import Button from '@/components/Button'
import Page from '@/components/Page'
import { useModalContext } from '@/components/providers/ModalProvider'

const Hero = () => {
  const { setComponent } = useModalContext()
  return (
    <Page.Header bgVideoSrc={header} bgSrc={headerImg} className='!h-[36rem] md:!h-[48rem]'>
      <div className='flex flex-col justify-center items-center max-w-3xl'>
        <Logo className='fill-white' />
        <h2 className='text-3xl whitespace-nowrap lg:text-4xl text-white font-bold mb-8'>
          A Running Advertisement
        </h2>

        <Anim.Sticky>
          <Button
            className='text-black bg-white'
            size='big'
            onClick={() => setComponent(<BookDemo />)}
          >
            Start Trial Now
          </Button>
        </Anim.Sticky>
      </div>
    </Page.Header>
  )
}

export default Hero
