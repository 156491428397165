import { FiCheck } from 'react-icons/fi'

import cn from '@/utils/cn'

import { StepperProps } from './types'

const Vertical = ({ activeStep, steps }: StepperProps) => {
  return (
    <div className='w-full'>
      <ul className='list-none'>
        {steps.map((step, i) => (
          <li key={`${step.title}-${i}`}>
            <div
              className='flex items-center hover:backdrop-brightness-90 cursor-pointer py-4'
              // onClick={() => onStepChange(i)}
            >
              {i < activeStep ? (
                <span className='w-8 h-8 flex justify-center items-center rounded-full text-white bg-green-500'>
                  <FiCheck />
                </span>
              ) : (
                <span
                  className={cn(
                    'w-8 h-8 flex justify-center items-center rounded-full text-white bg-gray-400',
                    {
                      'bg-blue-500': i === activeStep,
                    },
                  )}
                >
                  {i + 1}
                </span>
              )}
              <span
                className={cn('text-gray-500 ml-2', {
                  'font-bold text-black': i === activeStep,
                })}
              >
                {step.title}
              </span>
            </div>
            <div
              className={cn('max-h-0 transition-all duration-300 overflow-hidden', {
                'max-h-screen': i === activeStep,
              })}
            >
              {step.content}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Vertical
