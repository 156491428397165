import { motion } from 'framer-motion'

import { isNil } from '@/utils/notlodash'
import { gridColsMd } from '@/utils/tw-class'

import { CARD_MOTION, CONTAINER_MOTION } from './motion'
import { ItemProps, ListDisplayProps } from './type'

const Card = ({ icon, index, title, desc, numbered = false }: ItemProps) => {
  if (numbered && isNil(index)) {
    throw new Error('index must be provided for Simple style')
  }

  return (
    <motion.div
      {...CARD_MOTION}
      className='h-full flex flex-col items-center p-4 bg-white rounded md:p-8 md:shadow-xl'
    >
      <div className='relative mb-8'>
        {typeof icon === 'string' ? (
          <img src={icon} />
        ) : (
          <div className='rounded-full w-20 h-20 text-4xl flex justify-center items-center bg-arund'>
            {icon}
          </div>
        )}

        {numbered && (
          <div className='absolute text-lg top-0 right-0 bg-yellow-600 text-white rounded-full w-8 h-8 leading-8 text-center'>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {index! + 1}
          </div>
        )}
      </div>

      <div className='mb-2 text-center text-3xl font-bold'>{title}</div>
      <div className='text-gray-600 text-center text-lg'>{desc}</div>
    </motion.div>
  )
}

const SimpleForm = ({ items, cols = 1 }: ListDisplayProps) => {
  return (
    <motion.div {...CONTAINER_MOTION} className={`grid grid-cols-1 md:gap-8 ${gridColsMd(cols)}`}>
      {items.map((item, i) => (
        <Card key={`item-${i}`} {...item} index={i} />
      ))}
    </motion.div>
  )
}

export default SimpleForm
