/* eslint-disable @typescript-eslint/no-explicit-any */
// ts 3.6x
// eslint-disable-next-line @typescript-eslint/ban-types
export function debounce<T extends Function>(cb: T, wait = 20) {
  let h: any = 0
  const callable = (...args: any) => {
    clearTimeout(h)
    h = setTimeout(() => cb(...args), wait)
  }
  return <T>(<any>callable)
}

export function isNil(obj: any) {
  return obj === null || obj === undefined
}
