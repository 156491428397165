import { AnimationProps, MotionProps } from 'framer-motion'

export const DEFAULT_TRANSITION: Exclude<AnimationProps['transition'], undefined> = {
  type: 'spring',
  duration: 0.8,
  delay: 0.2,
}

export const DEFAULT_STAGGER_PARENT: MotionProps = {
  variants: {
    visible: {
      transition: { delayChildren: 0.5, staggerChildren: 0.1 },
    },
  },
  viewport: { once: true },
  initial: 'hidden',
  whileInView: 'visible',
}

export const DEFAULT_STAGGER_CHILD: MotionProps = {
  variants: {
    hidden: { opacity: 0, y: -50 },
    visible: { opacity: 1, y: 0 },
  },
  transition: {
    type: 'spring',
    duration: 0.8,
  },
}
