import { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { ReactComponent as Logo } from '@/assets/logo.svg'
import { useThemeContext } from '@/components/providers/ThemeProvider'
import cn from '@/utils/cn'

import BookDemo from '../BookDemo'
import { useModalContext } from '../providers/ModalProvider'

import CompanyDropdown from './CompanyDropdown'
import styles from './index.module.css'
import Menu, { MenuItem } from './Menu'
import ServiceDropdown from './ServiceDropdown'

const menuList: MenuItem[] = [
  {
    key: 'advertiser',
    link: '/advertiser',
    text: 'Brand & Agency', //TODO
  },
  {
    key: 'driver',
    link: '/driver',
    text: 'Driver',
  },
  {
    key: 'gallery',
    link: '/gallery',
    text: 'Gallery',
  },
  {
    key: 'service',
    text: 'Services',
    dropdown: <ServiceDropdown />,
  },
  {
    key: 'company',
    dropdown: <CompanyDropdown />,
    text: 'About Us',
  },
]

const Header = () => {
  const location = useLocation()
  const { setComponent } = useModalContext()
  const { transparentHeader } = useThemeContext()
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isTop, setIsTop] = useState(true)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    const listener = () => setIsTop(window.scrollY === 0)
    window.addEventListener('scroll', listener)
    return () => window.removeEventListener('scroll', listener)
  }, [])

  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'unset'
  }, [isMobileMenuOpen])

  useEffect(() => {
    setIsMobileMenuOpen(false)
  }, [location])

  const showDemo = useCallback(() => {
    setComponent(<BookDemo />)
  }, [setComponent])

  const isTransparent = isTop && !isDropdownOpen && transparentHeader

  const headerCls = isTransparent ? 'bg-opacity-0' : 'bg-opacity-90 shadow-xl backdrop-blur-sm'
  const textCls = isTransparent ? 'text-white' : 'text-gray-800'

  return (
    <header className={cn('fixed w-full z-30 transition-all duration-500 bg-white', headerCls)}>
      <div className='flex items-center justify-between mx-4 md:mx-auto md:max-w-7xl h-16 md:h-20'>
        {/* Logo */}
        <div className='flex-shrink-0 mr-4'>
          <Link to='/'>
            <Logo
              className={cn(
                isTransparent ? 'fill-white' : 'fill-arund',
                'h-10 transition-all duration-500',
              )}
            />
          </Link>
        </div>

        {/* Destop Menu */}
        <div className={cn('hidden h-full space-x-6 md:flex md:items-center', textCls)}>
          <Menu.Desktop items={menuList} onDropdownChange={setIsDropdownOpen} />
          <button
            className={cn(
              'text-xl px-3 py-2 rounded-lg md:text-base font-semibold hover:brightness-75 transition-all duration-500',
              { 'bg-white text-black': isTransparent },
              { 'text-white bg-arund ': !isTransparent },
            )}
            onClick={showDemo}
          >
            Book a Demo
          </button>
        </div>

        {/* Mobile Hamburger */}
        <button
          className={cn(
            styles['hamburger'],
            isMobileMenuOpen && styles['open'],
            'block md:hidden focus:outline-none',
            textCls,
          )}
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          <span
            className={cn(
              styles['hamburger-top'],
              isMobileMenuOpen && styles['open'],
              isTransparent && styles['is-transparent'],
            )}
          ></span>
          <span
            className={cn(
              styles['hamburger-middle'],
              isMobileMenuOpen && styles['open'],
              isTransparent && styles['is-transparent'],
            )}
          ></span>
          <span
            className={cn(
              styles['hamburger-bottom'],
              isMobileMenuOpen && styles['open'],
              isTransparent && styles['is-transparent'],
            )}
          ></span>
        </button>
      </div>

      {/* Mobile Menu */}
      <div
        className={cn(
          'absolute mt-4 left-4 right-4 rounded bg-white shadow max-h-0  h-[calc(90vh-100px)] overflow-hidden transition-all duration-500',
          { 'max-h-[calc(90vh-100px)]': isMobileMenuOpen },
        )}
      >
        <div className='flex flex-col h-full items-stretch justify-between py-8 overflow-scroll'>
          <div className='flex-1'>
            <Menu.Mobile items={menuList} onDropdownChange={setIsDropdownOpen} />
          </div>
          <button
            className='text-xl px-3 py-2 mx-8 mt-4 text-white bg-black rounded-lg md:text-base font-semibold hover:brightness-75'
            onClick={showDemo}
          >
            Book a Demo
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
