import Horizontal from './Horizontal'
import { StepperProps } from './types'
import Vertical from './Vertical'

type Props = {
  type: 'vertical' | 'horizontal'
} & StepperProps

const Stepper = ({ type, ...props }: Props) => {
  return type == 'vertical' ? <Vertical {...props} /> : <Horizontal {...props} />
}

export default Stepper
