import header from '@/assets/auto/2.jpg'
import Page from '@/components/Page'
import MobileApp from '@/sections/MobileApp'


const AppPage = () => {
  return (
    <Page>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>Mobile App</Page.Header.Title>
        <Page.Header.Subtitle>Download and register today to be notified</Page.Header.Subtitle>
      </Page.Header>

      <MobileApp />

      <Page.Footer />
    </Page>
  )
}

export default AppPage
