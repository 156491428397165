import { BsClipboardData } from 'react-icons/bs'
import { FiClock, FiMapPin } from 'react-icons/fi'
import { IoCarSportOutline } from 'react-icons/io5'

import ListDisplay from '@/components/ListDisplay'
import Section from '@/components/Section'

const ITEMS = [
  {
    icon: <FiMapPin className='text-white' />,
    title: 'Geographic',
    desc: 'Precise targeting of the area of interest that can maximize the exposure',
  },
  {
    icon: <FiClock className='text-white' />,
    title: 'Time',
    desc: 'Enhanced exposure estimation based on time and area',
  },
  {
    icon: <BsClipboardData className='text-white' />,
    title: 'Data',
    desc: 'Detailed report after every campaign',
  },
  {
    icon: <IoCarSportOutline className='text-white' />,
    title: 'Super car',
    desc: 'Special promotion plan that generates extra attractiveness',
  },
]

const DataDriven = () => {
  return (
    <Section>
      <Section.Title>Data Driven Advertising</Section.Title>
      <Section.Subtitle>
        Streamline your advertising campaign by precisely targeting your ideal customer. Avoid
        inefficiencies, and optimize your campaign with dynamically generated data!
      </Section.Subtitle>

      <ListDisplay.SimpleForm items={ITEMS} cols={4} />
    </Section>
  )
}

export default DataDriven
