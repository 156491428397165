import { FiMapPin } from 'react-icons/fi'

import canadianMap from '@/assets/canada-map.svg'
import { useDefaultMediaQuery } from '@/hooks/useMediaQuery'
import cn from '@/utils/cn'

const LABEL_STYLE =
  'text-center text-white text-3xl bold hover:scale-110 duration-150 ease-in-out cursor-pointer'

type Props = {
  onHover: (city: string) => void
}

const LocationMap = ({ onHover }: Props) => {
  const { isMd } = useDefaultMediaQuery()

  return (
    <div className='mx-auto md:mt-0'>
      <div className='relative'>
        <img className='opacity-30 -rotate-10' src={canadianMap} />
        <div
          className={cn('absolute bottom-[28%]', LABEL_STYLE)}
          onMouseEnter={() => onHover('vancouver')}
          onMouseLeave={() => onHover('')}
        >
          {isMd ? 'Vancouver' : <FiMapPin />}
        </div>
        <div
          className={cn('absolute bottom-[6%] right-[30%]', LABEL_STYLE)}
          onMouseEnter={() => onHover('toronto')}
          onMouseLeave={() => onHover('')}
        >
          {isMd ? 'Toronto' : <FiMapPin />}
        </div>
        <div
          className={cn('absolute bottom-[9%] right-[26%]', LABEL_STYLE)}
          onMouseEnter={() => onHover('ottawa')}
          onMouseLeave={() => onHover('')}
        >
          {isMd ? 'Ottawa' : <FiMapPin />}
        </div>
      </div>
    </div>
  )
}

export default LocationMap
