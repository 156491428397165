import { motion } from 'framer-motion'

import { BaseUI } from '@/components/types'
import cn from '@/utils/cn'

import { subtitleMotion, titleMotion } from './motion'

const Title = ({ children, className }: BaseUI) => (
  <motion.h2
    {...titleMotion}
    className={cn('text-4xl md:text-7xl font-bold mb-4 text-center font-display', className)}
  >
    {children}
  </motion.h2>
)

const Subtitle = ({ children, className }: BaseUI) => (
  <motion.h3
    {...subtitleMotion}
    className={cn('text-xl text-gray-600 my-8 text-center', className)}
  >
    {children}
  </motion.h3>
)

const SectionComponent = ({ children, className }: BaseUI) => (
  <section className={cn('relative max-w-6xl mx-auto pt-8 px-4 md:pt-24 md:px-0', className)}>
    {children}
  </section>
)

const Section = Object.assign(SectionComponent, {
  Title,
  Subtitle,
})

export default Section
