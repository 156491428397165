import adhocBg from '@/assets/adhoc-redbull.jpg'
import sharedCarBg from '@/assets/auto/1.jpg'
import DoorSelector from '@/components/DoorSelector'
import Section from '@/components/Section'

const OurService = () => {
  return (
    <Section className='w-full max-w-none bg-black'>
      <Section.Title className='text-white mb-6 md:mb-12'>Our Service</Section.Title>
      <Section.Subtitle className='text-gray-200'>
        We provide a full service to help design and build your advertisement campaign end-to-end.
      </Section.Subtitle>

      <DoorSelector
        doors={[
          {
            title: 'Shared Car Advertising',
            description:
              "Transforming Uber, Lyft, and other ride-share vehicles into thousands + of mobile billboards that drive your brand's message across the Canada.",
            image: sharedCarBg,
            link: '/campaign',
          },
          {
            title: 'Adhoc Event Pop Up',
            description:
              'Elevate your brand at any event, from NHL games to concerts. With unique car wraps and customized inflatable displays, we ensure you shine wherever your audience is.',
            image: adhocBg,
            link: '/adhoc',
          },
        ]}
      />

      {/* <motion.div
        {...DEFAULT_STAGGER_PARENT}
        className='grid grid-cols-1 md:my-20 md:grid-cols-2 md:gap-20'
      >
        <Carousel />

        <div className='flex flex-col'>
          <ListItem
            icon={<BsBox2Heart color='#1A315D' size={60} />}
            title='Design'
            description='We understand your business and work with talented designers to make customized design to help maximize your influence.'
          />
          <ListItem
            icon={<BsMegaphone color='#1A315D' size={60} />}
            title='Marketing'
            description='We provide unique marketing strategy to effectively target the audience.'
          />
          <ListItem
            icon={<BsCarFront color='#1A315D' size={60} />}
            title='Car Advertising'
            description='With our strong expertise in car advertising, your ad will get the most attraction where you need it.'
          />
          <ListItem
            icon={<BsBarChartLine color='#1A315D' size={60} />}
            title='Analytics'
            description='Use our online portal to see the detailed metrics of your advertisement, including area-of-interest, location and time.'
          />
        </div>
      </motion.div> */}
    </Section>
  )
}

export default OurService
