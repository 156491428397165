import { useFormik } from 'formik'

import Button from '@/components/Button'
import InputField from '@/components/Input/InputField'

import { CommonProps } from './types'

const Info = ({ recordResponse, setStep }: CommonProps) => {
  const formik = useFormik({
    initialValues: {
      company: '',
      industry: '',
      city: '',
    },
    onSubmit: (values) => {
      recordResponse(values)
      setStep((p) => p + 1)
    },
  })

  return (
    <form className='h-full max-w-xl mx-auto' onSubmit={formik.handleSubmit}>
      <div className='flex flex-col h-full'>
        <InputField
          id='company'
          required
          label='What is your company name?'
          placeholder='Business'
          value={formik.values.company}
          onChange={formik.handleChange}
        />
        <InputField
          id='industry'
          label='Tell us the industry of your business.'
          placeholder='Industry'
          value={formik.values.industry}
          onChange={formik.handleChange}
        />
        <InputField
          id='city'
          label='What is the targeted city for advertisement?'
          placeholder='City'
          value={formik.values.city}
          onChange={formik.handleChange}
        />
        <Button type='submit' className='mt-auto mb-0 rounded-lg bg-blue-500'>
          Next
        </Button>
      </div>
    </form>
  )
}

export default Info
