export function gridColsMd(cols: number) {
  switch (cols) {
    case 1:
      return 'md:grid-cols-1'
    case 2:
      return 'md:grid-cols-2'
    case 3:
      return 'md:grid-cols-3'
    case 4:
      return 'md:grid-cols-4'
  }
}
