import checkMatch from '@/assets/business-solution-rafiki.svg'
import driveEarn from '@/assets/car-driving-cuate.svg'
import pickupAttach from '@/assets/car-driving-rafiki.svg'
import signup from '@/assets/mobile-login-rafiki.svg'
import ListDisplay from '@/components/ListDisplay'
import Section from '@/components/Section'

interface HowItWorksProps {
  appRef: React.RefObject<HTMLDivElement>;
}

const HowItWorks = (props: HowItWorksProps) => {
  const scrollToApp = () => {
    props.appRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const ITEMS = [
    {
      icon: signup,
      title: 'Sign up',
      desc: (
        <>
          Download the Arund App from the App Store, sign up and become an Arunner! Click{' '}
          <span
            onClick={scrollToApp}
            className="text-blue-500 cursor-pointer underline"
          >
            here
          </span>{' '}
          to get started.
        </>
      ),
    },
    {
      icon: checkMatch,
      title: 'Check and match',
      desc: 'We will run a simple background check and match you with the advertisement in your area. You can accept a campaign you see one in the App home page.',
    },
    {
      icon: pickupAttach,
      title: 'Pick and Stick',
      desc: 'Once you are notified that Ad stickers are ready for pickup, drop by the designated location to pick up the sticker. Simply stick it on your car, and you are ready to go!',
    },
    {
      icon: driveEarn,
      title: 'Drive and earn!',
      desc: 'We will guide you to verify the execution of the Ads (photo or video validation of sticker and mileage). Once we confirm that you have completed the terms, we will deposit the payment to your account!',
    },
  ]

  return (
    <Section className='max-w-7xl'>
      <Section.Title>How to become an Arund Driver</Section.Title>

      <ListDisplay.TimelineForm items={ITEMS} numbered />
    </Section>
  )
}

export default HowItWorks
