import { motion } from 'framer-motion'
import { useRef } from 'react'

import auto1 from '@/assets/auto/1.jpg'
import tiff1 from '@/assets/tiff/1.jpg'
import tkfw1 from '@/assets/tkfw/1.jpg'
import Section from '@/components/Section'
import { useDefaultMediaQuery } from '@/hooks/useMediaQuery'

const IMAGES = [auto1, tiff1, tkfw1]

const Content = () => {
  return (
    <>
      <div className='text-xl text-gray-900 my-8'>
        {`Welcome to Arund! We're all about bringing your brand to life on the streets. As a top
        Shared Car Advertising Company, we use innovative out-of-home marketing strategies to get
        your message out there. Our fleet includes everything from food delivery cars to
        ride-shares, so your ads can travel all over the city.`}
      </div>
      <div className='text-xl text-gray-900 my-8'>
        {`With over 3,000 drivers across Canada and cutting-edge technology, we tailor our services to
        boost your brand's visibility and engagement. Whether it's our shared car advertising that
        naturally integrates into daily commutes or our on-demand ad-hoc event car pop-ups that grab
        attention at special events, we've got you covered.`}
      </div>
      <div className='text-xl text-gray-900 my-8'>
        {`Let's hit the road and take your brand to new heights! Arund is your perfect solution to
        boost your brand awareness.`}
      </div>
    </>
  )
}

const AboutArundDesktop = () => {
  const ref = useRef<HTMLDivElement>(null)

  return (
    <Section className='w-full max-w-none md:pt-0'>
      <div className='grid grid-cols-2'>
        <div className='sticky top-20 h-screen flex'>
          <motion.div className='self-center mx-16'>
            <Section.Title>Who Are We?</Section.Title>
            <Section.Subtitle>
              We provide world-class advertisement for your business
            </Section.Subtitle>

            <Content />
          </motion.div>
        </div>

        <div ref={ref} className='w-full'>
          {IMAGES.map((img, i) => (
            <img key={i} src={img} />
          ))}
        </div>
      </div>
    </Section>
  )
}

const AboutArundMobile = () => {
  return (
    <Section>
      <Section.Title>Who Are We?</Section.Title>
      <Section.Subtitle>We provide world-class advertisement for your business</Section.Subtitle>

      <Content />
      <div className='w-screen -m-4'>
        {IMAGES.slice(0, 3).map((img, i) => (
          <img key={i} src={img} />
        ))}
      </div>
    </Section>
  )
}

const AboutArund = () => {
  const { isMd } = useDefaultMediaQuery()
  return isMd ? <AboutArundDesktop /> : <AboutArundMobile />
}

export default AboutArund
