import header from '@/assets/tiff/2.jpg'
import Page from '@/components/Page'
import Research from '@/sections/Research'


const Advantages = () => {
  return (
    <Page>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>Advantages</Page.Header.Title>
        <Page.Header.Subtitle>Next generation advertisement strategy</Page.Header.Subtitle>
      </Page.Header>

      <Research />

      <Page.Footer />
    </Page>
  )
}

export default Advantages
