import header from '@/assets/tiff/6.jpg'
import Page from '@/components/Page'

import Autoshow from './Autoshow'
import Tiff from './Tiff'
import TkfwOttawa from './TkfwOttawa'

const Gallery = () => {
  return (
    <Page>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>Gallery</Page.Header.Title>
        <Page.Header.Subtitle>See what we have accomplished</Page.Header.Subtitle>
      </Page.Header>

      <Tiff />
      <Autoshow />
      <TkfwOttawa />

      <div className='bg-black h-20 m:h-[80px]' />
      <Page.Footer />
    </Page>
  )
}

export default Gallery
