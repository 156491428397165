import { useRef } from 'react'

import stayTuned from '@/assets/ok-rafiki.svg'
import header from '@/assets/tiff/2.jpg'
import Anim from '@/components/Anim'
import Button from '@/components/Button'
import Page from '@/components/Page'
import MobileApp from '@/sections/MobileApp'

import FAQ from './FAQ'
import HowItWorks from './HowItWorks'

const Arunner = () => {
  const appRef = useRef<HTMLDivElement>(null)

  return (
    <Page>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>You Drive, We Pay</Page.Header.Title>
        <Page.Header.Subtitle>
          {'Earn hundreds per month driving as an Arund Driver'}
        </Page.Header.Subtitle>

        <Anim.Sticky className='mt-10'>
          <Button
            className='text-xl bg-white text-black py-4 px-6 w-auto'
            onClick={() => appRef.current?.scrollIntoView({ behavior: 'smooth' })}
          >
            Download Now
          </Button>
        </Anim.Sticky>
      </Page.Header>

      <HowItWorks appRef={appRef}/>

      <div className='flex flex-col items-center my-12 md:my-16'>
        <div className='text-3xl font-bold text-center'>Stay tuned for the next campaign!</div>
        <img className='w-60 h-60 m-4' src={stayTuned} />
      </div>

      <div ref={appRef}>
        <MobileApp />
      </div>

      <FAQ />
    </Page>
  )
}

export default Arunner
