const HEADER_CLS = 'font-bold my-4 text-2xl'
const PARAGRAPH_CLS = 'mb-4'

const TermsCondition = () => {
  return (
    <div className='max-w-6xl mx-auto px-2 py-8'>
      <h1 className='text-4xl font-bold mb-4'>Terms and Conditions</h1>
      <div className='text-gray-600 mb-4'>Updated Date: August 12, 2022</div>
      <p className={PARAGRAPH_CLS}>
        The Terms and Conditions constitute a legally binding agreement made between the Arund
        Driver (“
        <b>the driver</b>”, “<b>you</b>”, “<b>your</b>”) and Arund Inc. (“<b>Arund</b>”, “<b>we</b>
        ”, “<b>us</b>”, “<b>our</b>”), individually referred to as “<b>Party”</b>, and collectively
        the “<p>Parties”</p>. By participating in the Arund Driver Program, you agree that you have
        read, understand and agree the terms and conditions set below. Arund reserves the rights, in
        our sole discretion, to provide updates or modifications to these Terms and Conditions at
        any time and for any reason. The date of the latest updates or modifications will be
        reflected on the top of this site. It is your responsibility to periodically review these
        Terms and Conditions to stay informed of changes.
      </p>
      <h2 className={HEADER_CLS}>Privacy</h2>
      <p className={PARAGRAPH_CLS}>
        The Arund Driver Program enables data collection which will be used by Arund to screen
        potential Arund Driver from the sign-up pool, keep record of Arund Driver’s personal and
        vehicle information. Arund agrees to collect, use, or legally disclose all data collected in
        strict adherence to relevant privacy law and legislations. You provide consent to Arund to
        collect, use, or legally disclose your information. Arund agrees not to sell, rent, or
        otherwise transmit your information to third parties without your consent.
      </p>
      <h2 className={HEADER_CLS}>Arund Driver Sign-Up</h2>
      <p className={PARAGRAPH_CLS}>
        You agree to enter the Arund Driver’s pool by filling out the signup form available at
        arund.ca and signing up as a potential Arund Driver. We will review your information and
        contact you through email and/or text messages for further program details. Once a mutual
        agreement has been achieved, we will proceed further to contract signing stage. You agree to
        check your spam regularly as we do respond to all sign-up or general inquiries within 48
        hours.
      </p>
      <h2 className={HEADER_CLS}>Car Advertising Sticker </h2>
      <p className={PARAGRAPH_CLS}>
        Once the parties form a contractual relationship, the Arund Driver will be advised of the
        location and time for picking up the advertisement sticker (the “<b>Sticker”</b>). The Arund
        Driver shall post the Sticker on the vehicle registered to the program, for the purpose of
        advertising services or products, as per contract details.
      </p>
      <h2 className={HEADER_CLS}>Eligibility Requirements </h2>
      <p className={PARAGRAPH_CLS}>
        The following requirements shall be met for the Arund Driver to participate in the Arund
        Driver Program:
      </p>
      <ul className='list-disc list-inside'>
        <li>Has valid Canadian Driver’s license (G2 or G) </li>
        <li>Be at least 18 years old</li>
        <li>Be the primary owner of the vehicle registered for the program </li>
        <li>Has valid insurance for the duration of the program</li>
        <li>Has good driving records</li>
      </ul>
      <p className={PARAGRAPH_CLS}>
        Background check will be conducted upon Arund Driver’s sign up. The Arund Driver shall be
        honest with the eligibility information and is encouraged to contact us regarding any
        questions about the requirements.
      </p>
      <h2 className={HEADER_CLS}>General Considerations and Conducts</h2>
      <p className={PARAGRAPH_CLS}>
        The Arund Driver will access program information and agreement details through a separate
        contract. Some general considerations and conducts expected for the Arund Driver are:
      </p>
      <ul className='list-disc list-inside'>
        <li>
          The Arund Driver shall post the Sticker for the entire duration that is agreed upon.{' '}
        </li>
        <li> The Arund Driver shall achieve the kilometer goal as required by the contract. </li>
        <li>The Arund Driver shall report any accidents in the duration of the contract. </li>
        <li>
          The Arund Driver shall report any issues related to the Sticker to Arund in the duration
          of the contract.
        </li>
        <li>
          The Arund Driver shall be responsible for the cleanliness and proper presentation of the
          vehicles.
        </li>
      </ul>
      <h2 className={HEADER_CLS}>Payment and Penalties</h2>
      <p className={PARAGRAPH_CLS}>
        The payment details will be specified in the contract. Arund agrees not to charge any extra
        cost to the Arund Driver for the program participation.
      </p>
      <p className={PARAGRAPH_CLS}>
        If the Sticker is damaged due to quality issues, malicious damage or force majeure, the
        Arund Driver shall contact Arund immediately for help.
      </p>
      <p className={PARAGRAPH_CLS}>
        If the Sticker is removed before the term ends, it suggests that the Arund Driver decides to
        opt out of the current contract. Generally, a warning will be given when the Arund Driver
        chooses to opt out without any prompt notification for the first time. Subsequent offences
        will result in Arund Driver’s ineligibility for the current and future program
        participations. Payment deductions may apply.
      </p>
      <h2 className={HEADER_CLS}>Termination</h2>
      <p className={PARAGRAPH_CLS}>
        Throughout your participation in the Arund Driver Program, these Terms and Conditions will
        continue to be in full force and effect. We reserve the right to refuse access to and use of
        the website to anyone for any reason or no reason at all, including but not limited to the
        breach of any representation, warranty, or covenant contained in these terms and conditions
        or of any applicable law or regulation. This decision will be made at our sole discretion
        and without prior notice or liability. At any time, we may stop allowing you to access the
        website, deactivate your account, and remove any material or information that you have
        submitted without any notice.
      </p>
      <p className={PARAGRAPH_CLS}>
        If we terminate or suspend your account for any reason, you are prohibited from registering
        and setting up a new account. We reserve the right to pursue appropriate legal action,
        including without limitation seeking civil, criminal, and injunctive remedy, in addition to
        terminating or suspending your account.{' '}
      </p>
      <p className={PARAGRAPH_CLS}>
        For further questions and concerns, please contact Arund at{' '}
        <a href='mailto: info@arund.ca' className='text-blue-600'>
          info@arund.ca
        </a>{' '}
        for clarifications.
      </p>
    </div>
  )
}

export default TermsCondition
