import { AnimatePresence, motion } from 'framer-motion'
import { ReactNode, useState } from 'react'
import { FiChevronDown } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import cn from '@/utils/cn'

import { DROPDOWN_MOTION } from './motion'

export type MenuItem = {
  key: string
  link?: string
  text: ReactNode
  dropdown?: ReactNode
}

type Props = {
  items: MenuItem[]
  onDropdownChange: (visible: boolean) => void
}

type ItemProps = {
  item: MenuItem
  isOpen?: boolean
  setOpenedItem?: (key?: string) => void
}

const TITLE_CSS = 'text-xl self-center text-center md:text-base hover:font-semibold cursor-pointer'

const MenuItem = ({ item, isOpen, setOpenedItem }: ItemProps) => {
  const hasDropdown = !!item.dropdown
  const link = item.link || ''

  return !hasDropdown ? (
    <Link className={TITLE_CSS} to={link}>
      {item.text}
    </Link>
  ) : (
    <motion.div onMouseEnter={() => setOpenedItem?.(item.key)}>
      <div className={cn(TITLE_CSS, 'h-full flex items-center justify-center')}>
        {item.text}

        <FiChevronDown
          className={cn('inline w-4 h-4 mt-1 ml-1 transition-all duration-300', {
            'rotate-180': isOpen,
          })}
        />
      </div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            {...DROPDOWN_MOTION}
            animate={isOpen ? 'visible' : 'hidden'}
            className='absolute left-0 right-0 top-20 bg-white overflow-hidden'
            onMouseEnter={() => setOpenedItem?.(item.key)}
          >
            <div className='relative my-4'>{item.dropdown}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

const DesktopMenu = ({ items, onDropdownChange }: Props) => {
  const [openedItem, setOpenedItem] = useState<string>()

  return (
    <div
      className='h-full grid grid-cols-[repeat(5,minmax(100px,1fr))] gap-4'
      onMouseLeave={() => {
        setOpenedItem?.(undefined)
        onDropdownChange(false)
      }}
    >
      {items.map((item) => (
        <MenuItem
          key={item.key}
          item={item}
          isOpen={openedItem == item.key}
          setOpenedItem={(key) => {
            setOpenedItem(key)
            onDropdownChange(!!key)
          }}
        />
      ))}
    </div>
  )
}

const MobileDropDown = ({ title, content }: { title: ReactNode; content: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className='px-8 py-5 overflow-hidden' onClick={() => setIsOpen(!isOpen)}>
      <div className='flex items-center '>
        <div className='text-xl flex-1'>{title}</div>
        <FiChevronDown
          className={cn('w-4 h-4 ml-auto flex-shrink-0 origin-center transition duration-300', {
            'rotate-180': isOpen,
          })}
        />
      </div>

      <div
        className={cn('max-h-0 overflow-hidden transition-all duration-300', {
          'max-h-screen': isOpen,
        })}
      >
        {content}
      </div>
    </div>
  )
}

const MobileMenu = ({ items }: Props) => {
  return (
    <div className='flex flex-col items-stretch z-99'>
      {items.map((item) =>
        item.dropdown ? (
          <MobileDropDown key={item.key} title={item.text} content={item.dropdown} />
        ) : (
          <Link key={item.key} className='text-xl px-8 py-4' to={item.link || ''}>
            {item.text}
          </Link>
        ),
      )}
    </div>
  )
}

const Menu = {
  Desktop: DesktopMenu,
  Mobile: MobileMenu,
}

export default Menu
