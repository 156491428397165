import { motion } from 'framer-motion'
import { ReactNode, useState } from 'react'

import { useDefaultMediaQuery } from '@/hooks/useMediaQuery'
import cn from '@/utils/cn'

import FlipText from '../FlipText'

import { arrowMotion, imageMotion, overlayMotion } from './motion'

type Door = {
  image: string
  title: ReactNode
  description: ReactNode
  link: string
}

type Props = {
  doors: Door[]
}

const ARROW_WIDTH = 30
const ARROW_HEIGHT_HALF = 10
const ARROW_OFFSET = 2

const DoorSelector = ({ doors }: Props) => {
  const { isMd } = useDefaultMediaQuery()
  const [hovered, setHovered] = useState<string>('')
  const mobileClass = 'h-[24rem]'
  const desktopClass = 'h-[48rem] flex-1'

  return (
    <div className='flex flex-col md:flex-row cursor-pointer'>
      {doors.map((door, i) => {
        const key = `door-${i}`
        return (
          <motion.a
            key={key}
            href={door.link}
            className={cn('relative overflow-hidden', isMd ? desktopClass : mobileClass)}
            onMouseEnter={() => setHovered(key)}
            onMouseLeave={() => setHovered('')}
          >
            <motion.img
              {...imageMotion}
              animate={hovered === key ? 'hovered' : 'initial'}
              src={door.image}
              className='w-full h-full object-cover'
            />

            <div className='absolute z-10 inset-10 md:inset-20 flex flex-col gap-2 justify-center items-center'>
              <FlipText
                animate={hovered === key}
                className='text-gray-100 text-4xl md:text-6xl font-semibold'
              >
                {door.title}
              </FlipText>
              <div className='text-gray-300 text-xl'>{door.description}</div>

              <motion.svg
                width={50}
                height={50}
                animate={hovered === key || !isMd ? 'hovered' : 'initial'}
                stroke='#fff'
                viewBox='0 0 30 30'
              >
                <motion.line
                  x1={ARROW_OFFSET}
                  y1={ARROW_HEIGHT_HALF}
                  x2={ARROW_WIDTH - ARROW_OFFSET}
                  y2={ARROW_HEIGHT_HALF}
                  strokeLinecap='round'
                  strokeWidth={2}
                  {...arrowMotion}
                />
                <motion.line
                  x1={ARROW_WIDTH - ARROW_HEIGHT_HALF - ARROW_OFFSET}
                  y1={ARROW_OFFSET}
                  x2={ARROW_WIDTH - ARROW_OFFSET}
                  y2={ARROW_HEIGHT_HALF}
                  strokeLinecap='round'
                  strokeWidth={2}
                  {...arrowMotion}
                />
                <motion.line
                  x1={ARROW_WIDTH - ARROW_HEIGHT_HALF - ARROW_OFFSET}
                  y1={ARROW_HEIGHT_HALF * 2 - ARROW_OFFSET}
                  x2={ARROW_WIDTH - ARROW_OFFSET}
                  y2={ARROW_HEIGHT_HALF}
                  strokeLinecap='round'
                  strokeWidth={2}
                  {...arrowMotion}
                />
              </motion.svg>
            </div>

            <motion.div
              {...overlayMotion}
              className='absolute top-0 left-0 bottom-0 right-0'
              animate={hovered === key ? 'hovered' : 'initial'}
            />
          </motion.a>
        )
      })}
    </div>
  )
}

export default DoorSelector
