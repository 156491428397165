import { MotionProps } from 'framer-motion'

import { DEFAULT_TRANSITION } from '@/utils/base-motion'

export const titleMotion: MotionProps = {
  variants: {
    hidden: { opacity: 0, y: -70, transition: DEFAULT_TRANSITION },
    visible: { opacity: 1, y: 0, transition: DEFAULT_TRANSITION },
  },
  initial: 'hidden',
  whileInView: 'visible',
}

export const subtitleMotion: MotionProps = {
  variants: {
    hidden: {
      opacity: 0,
      y: -70,
      transition: { ...DEFAULT_TRANSITION, delay: DEFAULT_TRANSITION.delay || 0 + 0.2 },
    },
    visible: { opacity: 1, y: 0, transition: DEFAULT_TRANSITION },
  },
  initial: 'hidden',
  whileInView: 'visible',
}
