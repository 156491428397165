import { FiDollarSign, FiTarget } from 'react-icons/fi'
import { IoCheckmarkCircleSharp, IoStatsChartSharp } from 'react-icons/io5'

import ListDisplay from '@/components/ListDisplay'
import Section from '@/components/Section'

const ITEMS = [
  {
    icon: <IoStatsChartSharp className='text-white' />,
    title: 'Organic Connections',
    desc: (
      <>
        <div>Natural flow of interpersonal relationships of individual car owners</div>
        <div>Integrate naturally and deeply into the neighborhoods</div>
      </>
    ),
  },
  {
    icon: <FiDollarSign className='text-white' />,
    title: 'Cost Efficient',
    desc: (
      <>
        <div>Lower price than digital ad, billboards, subway, etc.s</div>
        <div>More precise targeting than other advertisement</div>
      </>
    ),
  },
  {
    icon: <FiTarget className='text-white' />,
    title: 'Active Engagement',
    desc: (
      <>
        <div>Closely engage with audience throughout their travel</div>
        <div>Pinpoint exposure to target ideal audience and locations</div>
      </>
    ),
  },
  {
    icon: <IoCheckmarkCircleSharp className='text-white' />,
    title: 'Strong Exposure',
    desc: (
      <>
        <div>Build solid brand awareness through high-frequency and large-scale exposure</div>
        <div>Widely publicize your advertisement</div>
      </>
    ),
  },
]

const Why = () => {
  return (
    <Section>
      <Section.Title>Advantages</Section.Title>
      <Section.Subtitle>
        Advertisement driven around the city brings you the most exposure you need
      </Section.Subtitle>

      <ListDisplay.SimpleForm items={ITEMS} cols={2} />
    </Section>
  )
}

export default Why
