import BookDemo from '@/components/BookDemo'
import Button from '@/components/Button'
import { useModalContext } from '@/components/providers/ModalProvider'
import Section from '@/components/Section'

const LearnMore = () => {
  const { setComponent } = useModalContext()
  return (
    <Section className='w-full max-w-none py-20 bg-gradient-to-r from-arund to-sky-700 px-4'>
      <Section.Title className='text-white'>GET IN TOUCH TODAY</Section.Title>
      <Section.Subtitle className='text-gray-300'>
        With our next-generation advertisement strategy and affordable pricing, give it a try now!
      </Section.Subtitle>

      <Button
        className='mt-8 mx-auto bg-white text-black'
        size='big'
        onClick={() => setComponent(<BookDemo />)}
      >
        Get Started
      </Button>
    </Section>
  )
}

export default LearnMore
