const HEADER_CLS = 'font-bold my-4 text-2xl'
const PARAGRAPH_CLS = 'mb-4'

const PrivacyPolicy = () => {
  return (
    <div className='max-w-6xl mx-auto px-2 py-8'>
      <h1 className='text-4xl font-bold mb-4'>Privacy Policy</h1>
      <div className='text-gray-600 mb-4'>Updated Date: January 10, 2023</div>
      <p className={PARAGRAPH_CLS}>
        At <b>Arund</b>, we are committed to protecting the privacy of our users. This Privacy
        Policy explains how we collect, use, disclose, and protect your information when you use our
        mobile application. By using our app, you consent to the collection, use, and disclosure of
        your information as described in this Privacy Policy.
      </p>
      <h2 className={HEADER_CLS}>Information We Collect</h2>
      <ul>
        <li>
          Background Location Data: We collect your {`device's`} GPS coordinates to provide
          location-based services and features, such as providing you with relevant content based on
          your location.
        </li>
        <li>
          Device Information: We may collect information about your device, including the type of
          device, operating system, and unique device identifiers.
        </li>
      </ul>

      <h2 className={HEADER_CLS}>How We Use Your Information</h2>

      <ul>
        <li>
          Background Location Data: We use your location data to provide you with location-based
          services and features, such as providing you with relevant content based on your location.
          We also collect this information to track how much you use the app to award you
          accordingly. This is the primary feature of the mobile application <b>Arund</b>, and we
          expect your consent while using the app. We treat your safety of your data with highest
          attention, and we will not share this data with third parties unless in cases specified in
          the {`"Disclosure"`}
        </li>
        <li>
          Device Information: We use your device information to provide the {`app's`} features and
          to improve our app.
        </li>
      </ul>

      <h2 className={HEADER_CLS}>Disclosure of Your Information</h2>
      <p className={PARAGRAPH_CLS}>
        We may disclose your information to third parties for the following reasons:
      </p>

      <ul>
        <li>As required by law, such as to comply with a subpoena, or similar legal process.</li>
        <li>
          When we believe in good faith that disclosure is necessary to protect our rights, protect
          your safety or the safety of others, investigate fraud, or respond to a government
          request.
        </li>
        <li>
          With our trusted services providers who work on our behalf, do not have an independent use
          of the information we disclose to them, and have agreed to adhere to the rules set forth
          in this privacy statement.
        </li>
      </ul>

      <h2 className={HEADER_CLS}>Security</h2>

      <p className={PARAGRAPH_CLS}>
        We take reasonable precautions to protect your information from loss, theft, misuse,
        unauthorized access, disclosure, alteration, and destruction. However, no security system is
        impenetrable, and we cannot guarantee the security of your information.
      </p>

      <h2 className={HEADER_CLS}>Opting out of Location Services</h2>
      <p className={PARAGRAPH_CLS}>
        You can stop the collection of location data by turning off location services on your
        device. However, some features of the app may no longer work properly. You may not receive
        an accurate payment for how much you drive.
      </p>

      <h2 className={HEADER_CLS}>Changes to this Privacy Policy</h2>

      <p className={PARAGRAPH_CLS}>
        We may update this Privacy Policy from time to time. If we make changes to this Privacy
        Policy, we will notify you by revising the date at the top of this Privacy Policy and, in
        some cases, we may provide you with additional notice (such as by adding a statement to our
        homepage or sending you an email notification). We encourage you to review this Privacy
        Policy regularly for any changes.
      </p>

      <h2 className={HEADER_CLS}>Contact Us</h2>

      <p className={PARAGRAPH_CLS}>
        If you have any questions about this Privacy Policy, please contact us at arunner@arund.ca.
      </p>

      <p className={PARAGRAPH_CLS}>
        Additionally, App should also include in-app disclosures and obtain affirmative, opt-in user
        consent for the collection, sharing, or use of location data. As well as, App should also
        have an explicit “permission” prompt when requesting access to {`user’s`} location data, and
        app should also provide a way for the user to change their mind and withdraw consent at any
        time.
      </p>
    </div>
  )
}

export default PrivacyPolicy
