import qrCode from '@/assets/wechat-qr-code.jpg'

const WechatQRCode = () => {
  return (
    <div className='bg-black flex-grow flex items-center justify-center h-full w-full'>
      <img className='w-screen md:w-[400px]' src={qrCode} />
    </div>
  )
}

export default WechatQRCode
