import header from '@/assets/tkfw/1.jpg'
import Page from '@/components/Page'

import HowItWorks from './HowItWorks'
import Why from './Why'

const Campaign = () => {
  return (
    <Page>
      <Page.Header bgSrc={header}>
        <Page.Header.Title>Campaign</Page.Header.Title>
        <Page.Header.Subtitle>
          The traditional advertisement strategy on a fixed term
        </Page.Header.Subtitle>
      </Page.Header>

      <HowItWorks />
      <Why />

      <Page.Footer />
    </Page>
  )
}

export default Campaign
