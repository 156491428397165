import { Link } from 'react-router-dom'

import Accordion from '@/components/Accordion'

const FAQ = () => {
  return (
    // setting default font size for Accordion content to text-lg
    <div className='max-w-4xl mx-auto flex flex-col justify-center items-center pb-10 px-6 text-xl md:py-20'>
      <div className='text-4xl font-bold text-center mb-4 md:mb-20 md:text-6xl'>
        Frequently Asked Questions
      </div>
      <div className='flex flex-col gap-4 w-full'>
        <Accordion
          title='How do I get started?'
          content='The easiest way to get started with Arund is to use the contact form to speak with one of Arund’s account representatives. We would love to hear about your goals and work together achieve them.'
        />
        <Accordion
          title='What are the market channels that Arund has access to?'
          content='Arund has a network of fleets and individual drivers across Canada. 
                We can serve almost any market you may look to target. We can also match you with the ideal drivers based on your target audience.'
        />
        <Accordion
          title='What’s the minimum duration of an Arund campaign?'
          content='Typical campaigns can last from 1 month to a year. Contact us to discuss your timeline!'
        />
        <Accordion
          title='What does pricing look like at Arund?'
          content='Costs vary based on campaigns. Comparative to many other marketing options, Arund is an affordable out-of-home solution. Specific costs are determined by campaign variables like location, duration, number of cars, sticker style, etc. 
        Contact us for a price estimate. '
        />
        <Accordion
          title='How long does it take for an ad to go from design to placement?'
          content='Specific timelines would depend on individual campaign strategies. In general, it would take approximately 2 to 6 weeks.'
        />
        <Accordion
          title='What if stickers are damaged during the campaign?'
          content='We engage closely with our drivers to ensure your sticker remains in tiptop shape! If accidental damage does occur, Arund will repair or replace the damaged sticker at no cost to you.'
        />
        <Accordion
          title='Still having more questions?'
          content={
            <p>
              <Link to='/contact' className='underline hover:cursor-pointer'>
                Contact us
              </Link>{' '}
              today! Our representatives would love to speak and meet with you, and provide you with
              more information on our stunning custom vehicle stickers and campaign!
            </p>
          }
        />
      </div>
    </div>
  )
}

export default FAQ
