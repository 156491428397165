import { motion } from 'framer-motion'
import { useMemo, useRef, useState } from 'react'

import BookDemo from '@/components/BookDemo'
import Button from '@/components/Button'
import { useModalContext } from '@/components/providers/ModalProvider'
import Section from '@/components/Section'
import { useDefaultMediaQuery } from '@/hooks/useMediaQuery'
import cn from '@/utils/cn'

import LocationMap from './LocationMap'

type CardProps = {
  isShown: boolean
  isFloat?: boolean
  title?: string
  description?: string
  regionMap?: {
    region: string
    rate: string
  }[]
}

const Card = ({ isShown, isFloat, title, description, regionMap }: CardProps) => {
  return (
    <div
      className={cn(
        {
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 p-8 rounded-2xl bg-neutral-800 duration-300 transition-all ease-in-out':
            isFloat,
        },
        {
          'p-4': !isFloat,
        },
        { 'opacity-0': !isShown },
      )}
    >
      <div className='text-2xl font-bold text-white text-center mx-auto md:text-4xl'>{title}</div>
      <div className='text-xl text-gray-400 text-center mx-auto mb-6 md:text-2xl'>
        {description}
      </div>
      {regionMap?.map((item) => (
        <div key={item.region} className='flex place-content-between'>
          <div className='text-gray-400 text-xl'>{item.region}</div>
          <div className='text-white text-xl'>{item.rate}</div>
        </div>
      ))}
    </div>
  )
}

const VANCOUVER = {
  title: 'Metro Vancouver',
  description: 'Estimated exposure rate',
  regionMap: [
    {
      region: 'Vancouver',
      rate: '390K',
    },
    {
      region: 'Coquitlam',
      rate: '300K',
    },
    {
      region: 'Richmond',
      rate: '252K',
    },
    {
      region: 'Surrey',
      rate: '186K',
    },
    {
      region: 'Burnaby',
      rate: '172K',
    },
  ],
}

const TORONTO = {
  title: 'Greater Toronto Area',
  description: 'Estimated exposure rate',
  regionMap: [
    {
      region: 'Toronto',
      rate: '456K',
    },
    {
      region: 'Markham',
      rate: '256K',
    },
    {
      region: 'Richmond Hill',
      rate: '218K',
    },
    {
      region: 'North York',
      rate: '194K',
    },
    {
      region: 'Vaughan',
      rate: '178K',
    },
    {
      region: 'Scarborough',
      rate: '170K',
    },
  ],
}

const OTTAWA = {
  title: 'Ottawa',
  description: 'Estimated exposure rate',
  regionMap: [
    {
      region: 'Ottawa',
      rate: '578K',
    },
  ],
}

const Where = () => {
  const { isMd } = useDefaultMediaQuery()
  const { setComponent } = useModalContext()
  const ref = useRef<HTMLDivElement>(null)
  const [selectedView, setSelectedView] = useState('toronto')
  const [isHovering, setIsHovering] = useState(false)

  const cardProp = useMemo(() => {
    switch (selectedView) {
      case 'vancouver':
        return VANCOUVER
      case 'toronto':
        return TORONTO
      case 'ottawa':
        return OTTAWA
    }
  }, [selectedView])

  return (
    <Section className='w-full max-w-none bg-black pb-4'>
      <div ref={ref} className='flex flex-col items-center md:items-start px-4'>
        <LocationMap
          onHover={(view) => {
            setIsHovering(!!view)
            view && setSelectedView(view)
          }}
        />
        <div className='absolute top-0 left-0 right-0 bottom-1/2 bg-gradient-to-b from-black to-transparent' />

        <motion.div className='w-full flex flex-col justify-center items-center my-16 z-10'>
          <Section.Title className='text-white'>Where We Operate</Section.Title>
          <Section.Subtitle className='text-gray-200'>
            We operate in the largest cities in Canada.
          </Section.Subtitle>

          <Button
            className='mt-6 bg-white text-black'
            size='big'
            onClick={() => setComponent(<BookDemo />)}
          >
            Learn More
          </Button>
        </motion.div>
      </div>

      <Card isShown={isHovering} isFloat {...cardProp} />

      {!isMd && <Card isShown {...VANCOUVER} />}
      {!isMd && <Card isShown {...TORONTO} />}
      {!isMd && <Card isShown {...OTTAWA} />}
    </Section>
  )
}

export default Where
