import { ReactNode, createContext, useContext, useMemo, useState } from 'react'

type ThemeState = {
  transparentHeader?: boolean
  setTransparentHeader: (v: boolean) => void
}

const ThemeContext = createContext<ThemeState>({
  setTransparentHeader: () => {
    return
  },
})

type Props = {
  children: ReactNode
}

const ThemeProvider = ({ children }: Props) => {
  const [transparentHeader, setTransparentHeader] = useState<boolean>()

  const value = useMemo(() => {
    return {
      transparentHeader,
      setTransparentHeader,
    }
  }, [transparentHeader])

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export default ThemeProvider

export const useThemeContext = () => useContext(ThemeContext)
