import { MotionProps } from 'framer-motion'

import { DEFAULT_TRANSITION } from '@/utils/base-motion'

export const imageMotion: MotionProps = {
  variants: {
    initial: {
      scale: 1,
      filter: 'blur(0)',
    },
    hovered: {
      scale: 1.1,
      filter: 'blur(4px)',
    },
  },
  initial: 'initial',
  transition: DEFAULT_TRANSITION,
}

export const overlayMotion: MotionProps = {
  variants: {
    initial: {
      backgroundColor: '#000',
      opacity: 0.7,
    },
    hovered: {
      backgroundColor: '#000',
      opacity: 0.6,
    },
  },
  initial: 'initial',
  transition: DEFAULT_TRANSITION,
}

export const arrowMotion: MotionProps = {
  variants: {
    initial: { pathLength: 0, opacity: 0 },
    hovered: {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { ...DEFAULT_TRANSITION, delay: 0 },
        opacity: { duration: 0.01 },
      },
    },
  },
}
