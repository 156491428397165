export type ContactUsAPIParams = {
  email: string
  firstName: string
  lastName: string
  message: string
  phone: string
  city: string
  province: string
}

export const putContactUs = (data: ContactUsAPIParams) =>
  fetch('https://u4fs5romek3puym6hvhvojiyry0ggemg.lambda-url.us-east-2.on.aws/', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

export type AdvertiserAPIParams = {
  email: string
  name: string
  company: string
  city: string
  phone: string
  industry: string
  budget: string
}

export const putAdvertiser = (data: AdvertiserAPIParams) =>
  fetch('https://do6v5suj2iev4r5vi3bglmql3y0kckyf.lambda-url.us-east-2.on.aws/', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

export type DriverAPIParams = {
  email: string
  firstName: string
  lastName: string
  address: string
  unitNumber: string
  city: string
  province: string
  country: string
  zipcode: string
  latitude: string
  longitude: string
  phone: string
  dateOfBirth: string
  gender: string
  employment: string
  referredBy: string

  onlyDriver: string
  make: string
  model: string
  modelYear: string
  color: string
  parkingLocation: string
  carType: string

  driveFor?: string
  businessName: string
  mileage: string
  like: string
  dislike: string
}

export const putDriver = (data: DriverAPIParams) =>
  fetch('https://djrarkps7nc6gkgh4q6huca6oq0dkrhb.lambda-url.us-east-2.on.aws/', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })

export const getAddressAutocompleteSuggestions = (input: string) =>
  fetch(`https://5agnzbputqftabmfhu6g2b73ua0mxhxs.lambda-url.us-east-2.on.aws?input=${input}`)

export const getAddressGeocodeInformation = (placeId: string) =>
  fetch(`https://lcfydvfsn2ty5xi55uajqe3j5a0holog.lambda-url.us-east-2.on.aws?place_id=${placeId}`)
