import { FiMail } from 'react-icons/fi'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from '@/assets/logo.svg'

type LinkItemProp = {
  text: string
  link: string
  outside?: boolean
  newWindow?: boolean
}

const LinkItem = ({ text, link, outside = false, newWindow = false }: LinkItemProp) => {
  return (
    <li className='my-2'>
      {outside ? (
        <a
          className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
          href={link}
          target={newWindow ? '_blank' : undefined}
          rel='noreferrer'
        >
          {text}
        </a>
      ) : (
        <Link
          className='text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
          target={newWindow ? '_blank' : undefined}
          to={link}
        >
          {text}
        </Link>
      )}
    </li>
  )
}

const Footer = () => {
  return (
    <footer>
      <div className='max-w-6xl mx-auto px-4 sm:px-6'>
        {/* Top area: Blocks */}
        <div className='grid sm:grid-cols-12 sm:grid-rows-2 gap-8 py-8 md:py-12 border-t border-gray-200'>
          {/* 1st block */}
          <div className='sm:col-span-12 lg:col-span-3 row-span-2'>
            {/* Todo: get better logo ...  */}
            <Logo className='fill-arund max-h-16' />
          </div>

          {/* 2nd block */}
          <div className='sm:col-span-6 md:col-span-4 lg:col-span-3 row-span-2'>
            <h6 className='text-gray-800 font-medium mb-2'>Company</h6>
            <div className='text-sm text-gray-600 mb-2'>
              Arund is a Shared Car Advertising Company focusing on innovative Out-of-Home marketing
              creations and promotions
            </div>
            <div className='text-sm text-gray-600'>205 Placer Ct #202, North York, ON M2H 0A9</div>
          </div>

          {/* 3rd block */}
          <div className='sm:col-span-6 md:col-span-3 lg:col-span-2 row-span-2'>
            <h6 className='text-gray-800 font-medium mb-2'>Resources</h6>
            <ul className='text-sm'>
              <LinkItem link='/advertiser' text='Advertiser' />
              <LinkItem link='/driver' text='Driver' />
              <LinkItem link='/app' text='App' />
              <LinkItem link='/gallery' text='Gallery' />
              <LinkItem link='/campaign' text='Campaign' />
              <LinkItem link='/adhoc' text='Adhoc Event' />
              <LinkItem link='/contact' text='Contact Us' />
              <LinkItem link='/career' text='Career' />
            </ul>
          </div>

          {/* 4th block */}
          <div className='sm:col-span-6 md:col-span-3 lg:col-span-2 row-span-2'>
            <h6 className='text-gray-800 font-medium mb-2'>Social</h6>
            <ul className='text-sm'>
              <LinkItem
                link='https://www.linkedin.com/company/arund/'
                text='LinkedIn'
                outside
                newWindow
              />
              <LinkItem
                link='https://www.instagram.com/arund.canada/'
                text='Instagram'
                outside
                newWindow
              />
              <LinkItem
                link='https://www.facebook.com/ArundAdvertising'
                text='Facebook'
                outside
                newWindow
              />
              <LinkItem
                link='https://twitter.com/ArundMarketing'
                text='Twitter'
                outside
                newWindow
              />
              <LinkItem
                link='https://www.youtube.com/@arundadvertisement'
                text='YouTube'
                outside
                newWindow
              />
              <LinkItem
                link='https://www.xiaohongshu.com/user/profile/5f1714670000000001002edc'
                text='小红书'
                outside
                newWindow
              />
              <LinkItem link='/wechat-qrcode' text='微信' newWindow />
            </ul>
          </div>

          {/* 5th block, top */}
          <div className='sm:col-span-6 md:col-span-3 lg:col-span-2 row-span-1'>
            <h6 className='text-gray-800 font-medium mb-2'>Email</h6>
            <ul className='text-sm'>
              <li className='mb-2'>
                <a
                  href='mailto: info@arund.ca'
                  className='flex items-center text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
                >
                  info@arund.ca
                  <FiMail className='ml-2' />
                </a>
              </li>
            </ul>
          </div>

          {/* 5th block, bottom */}
          <div className='sm:col-span-6 md:col-span-3 lg:col-span-2 row-span-1'>
            <h6 className='text-gray-800 font-medium mb-2'>Products</h6>
            <ul className='text-sm'>
              <LinkItem
                link='https://play.google.com/store/apps/details?id=com.arund.driver&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                text='Play Store'
                outside
                newWindow
              />
              <LinkItem
                link='https://apps.apple.com/ca/app/arund/id6444404647?itsct=apps_box_badge&itscg=30200'
                text='App Store'
                outside
                newWindow
              />
            </ul>
          </div>
        </div>

        {/* Bottom area */}
        <div className='md:flex md:items-center md:justify-between py-4 md:py-8 border-t border-gray-200'>
          {/* Copyrights note */}
          <div className='text-sm text-gray-600 mr-4'>
            Copyright{' @ '}
            <a className='text-blue-600 hover:underline' href='https://arund.ca/'>
              Arund Inc
            </a>{' '}
            2024
          </div>

          {/* Legal  */}
          <div>
            <Link
              className='text-sm mr-1 text-gray-600 hover:text-gray-900 transition duration-150 ease-in-out'
              to='/terms-and-conditions'
            >
              {'Terms & Conditions'}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
