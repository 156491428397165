import MarkdownView from '@/components/MarkdownView'
import Page from '@/components/Page'
import Section from '@/components/Section'

import md from './RethinkingAdvertising.md'

const RethinkingAdvertising = () => {
  return (
    <Page>
      <Page>
        <Section>
          <MarkdownView src={md} />
        </Section>
        <Page.Footer />
      </Page>
    </Page>
  )
}

export default RethinkingAdvertising
