import PageLink from './PageLink'

const CompanyDropdown = () => {
  return (
    <div className='md:max-w-4xl grid md:grid-cols-2 md:gap-16 mx-auto'>
      <div className='flex flex-col mt-4 mb-0 md:my-12'>
        <div className='font-bold text-sm md:text-base md:mb-8'>Resources</div>
        <PageLink
          link='/app'
          title='Arund App'
          description='Mobile App for drivers to claim prizes and earn'
        />
        {/* <PageLink
          link='/portal'
          title='Client Portal'
          description='Client portal for clients to track their advertisement performance'
        /> */}
        <PageLink link='/blog' title='Blog Posts' description="See what's latest for us" />
      </div>

      <div className='flex flex-col mt-4 mb-0 md:my-12'>
        <div className='font-bold text-sm md:text-base md:mb-8'>Company</div>
        <PageLink link='/contact' title='Contact Us' description='Talk to us' />
        <PageLink link='/career' title='Career' description='Learn about our open positions' />
      </div>
    </div>
  )
}

export default CompanyDropdown
