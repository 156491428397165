import PageLink from './PageLink'

const ServiceDropdown = () => {
  return (
    <div className='md:max-w-4xl grid md:grid-cols-2 md:gap-16 mx-auto'>
      <div className='flex flex-col mt-4 mb-0 md:my-12'>
        <div className='font-bold text-sm md:text-base md:mb-8'>Advertisement</div>
        <PageLink
          link='/campaign'
          title='Campaign'
          description='Traditional approach for a fixed term'
        />
        <PageLink
          link='/adhoc'
          title='Adhoc Events'
          description='Guaranteed engagement for events'
        />
      </div>

      <div className='flex flex-col mt-4 mb-0 md:my-12'>
        <div className='font-bold text-sm md:text-base md:mb-8'>Solutions</div>
        <PageLink
          link='/advantages'
          title='Advantages'
          description='How moving advertisement is better'
        />
        <PageLink
          link='/analytics'
          title='Analytics'
          description='Detailed insights into advertisement performance'
        />
        {/* <PageLink link='/ai' title='AI Powered' description='AI technology for precise tracking' /> */}
      </div>
    </div>
  )
}

export default ServiceDropdown
