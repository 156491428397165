import Section from '@/components/Section'
import Stats from '@/components/Stats'

const StatsIntro = () => {
  return (
    <Section className='w-full max-w-none bg-black pb-20'>
      <div className='flex flex-col md:flex-row justify-evenly gap-8'>
        <Stats
          from={1823}
          to={3588}
          transform={(i) => new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(i)}
          desc='Drivers across Canada'
          className='text-white'
          delay={0}
        />

        <Stats
          from={1184}
          to={43929}
          transform={(i) => new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(i)}
          desc='Impressions per month'
          className='text-white'
          delay={0}
        />

        <Stats
          from={11284}
          to={439249}
          transform={(i) => new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(i)}
          desc='Mileage per month'
          className='text-white'
          delay={0}
        />
      </div>
    </Section>
  )
}

export default StatsIntro
