import advertiserBg from '@/assets/auto/1.jpg'
import driverBg from '@/assets/auto/2.jpg'
import DoorSelector from '@/components/DoorSelector'
import Section from '@/components/Section'

const CustomerSelector = () => {
  return (
    <Section className='w-full max-w-none p-0 md:p-0'>
      <DoorSelector
        doors={[
          {
            title: 'Driver',
            description: 'Interested in becoming a driver and start earning?',
            image: driverBg,
            link: '/driver',
          },
          {
            title: 'Advertiser',
            description: 'Want to take advantage of out-of-home advertisement?',
            image: advertiserBg,
            link: '/advertiser',
          },
        ]}
      />
    </Section>
  )
}

export default CustomerSelector
