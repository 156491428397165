import { useFormik } from 'formik'
import { useState } from 'react'
import { FiCheckCircle } from 'react-icons/fi'

import { ContactUsAPIParams, putContactUs } from '@/api'
import contactUs from '@/assets/contact-us-rafiki.svg'
import Button from '@/components/Button'
import InputField from '@/components/Input/InputField'
import Page from '@/components/Page'

const ContactUs = () => {
  const formik = useFormik<ContactUsAPIParams>({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      message: '',
      phone: '',
      city: '',
      province: '',
    },
    onSubmit: (values) => {
      setIsSubmitted(true)
      putContactUs(values).catch(() => setError('Cannot submit, please try again later'))
    },
  })

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [error, setError] = useState<string>()

  return (
    <Page>
      <Page.Header className='bg-arund h-[24rem] md:h-[28rem] xl:h-[28rem]'>
        <h1 className='text-4xl md:text-7xl font-bold mb-4 text-white text-center'>Contact Us</h1>
        <p className='text-xl text-gray-100 text-center'>
          {"If you have any questions about our business, we'd love to hear from you!"}
        </p>
      </Page.Header>
      <div className='max-w-6xl mx-auto px-2 my-8 md:my-16 md:px-8'>
        <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
          <div className='flex flex-col'>
            <div className='flex flex-col items-center md:items-start'>
              <h1 className='text-3xl font-bold mb-4 text-center'>Contact Us</h1>
              <p className='text-xl text-gray-600 mb-2 text-center md:text-start'>
                Give us a call to learn more. Alternatively, you could fill out this form and our
                team will get back to you as soon as possible.
              </p>
              <ul className='text-xl text-gray-600 list-disc list-inside'>
                <li>+1 647-821-9998</li>
                <li>+1 647-936-7989</li>
              </ul>
            </div>
            <div className='mx-auto w-full'>
              <img src={contactUs} />
            </div>
          </div>
          <div className='max-w-sm mx-auto flex-grow md:max-w-md'>
            <h1 className='text-3xl font-bold mb-2 text-center hidden md:block'>Get In Touch</h1>
            {/* Form */}
            {!isSubmitted ? (
              <form onSubmit={formik.handleSubmit}>
                <div className='flex flex-wrap mb-4'>
                  <div className='w-1/2 px-3'>
                    <InputField
                      id='firstName'
                      label='First Name'
                      placeholder='Ryan'
                      required
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className='w-1/2 px-3'>
                    <InputField
                      id='lastName'
                      label='Last Name'
                      placeholder='William'
                      required
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className='w-full px-3'>
                    <InputField
                      id='email'
                      label='Email'
                      placeholder='example@email.com'
                      type='email'
                      required
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className='w-full px-3'>
                    <InputField
                      id='phone'
                      label='Phone'
                      placeholder='123-456-7890'
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className='w-1/2 px-3'>
                    <InputField
                      id='city'
                      label='City'
                      placeholder='Toronto'
                      value={formik.values.city}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className='w-1/2 px-3'>
                    <InputField
                      id='province'
                      label='Province'
                      placeholder='Ontario'
                      value={formik.values.province}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className='w-full px-3'>
                    <InputField
                      id='message'
                      label='Message'
                      placeholder='Leave your message to us...'
                      isTextArea
                      required
                      value={formik.values.message}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>

                {error && <div className='text-red-500 text-center mb-4'>{error}</div>}

                <div className='flex flex-wrap mb-4'>
                  <div className='w-full px-3'>
                    <Button className='bg-gradient-to-r from-arund to-blue-900'>Submit</Button>
                  </div>
                </div>
              </form>
            ) : (
              <div className='flex flex-col items-center justify-center mt-20'>
                <FiCheckCircle className='w-10 h-10 mb-4 text-green-500' />
                <div className='text-xl text-center'>
                  Thank you for contacting us. We will get back to you as soon as possible!
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}

export default ContactUs
