import { ReactNode } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import auto1 from '@/assets/auto/1.jpg'
import auto2 from '@/assets/auto/2.jpg'
import tiff1 from '@/assets/tiff/1.jpg'
import Page from '@/components/Page'
import Section from '@/components/Section'
import cn from '@/utils/cn'

type ItemProps = {
  imgSrc: string
  title: ReactNode
  desc: ReactNode
  link: string
  className?: string
}

const CardLink = ({ imgSrc, title, desc, link, className }: ItemProps) => {
  const navigate = useNavigate()

  return (
    <Link
      className={cn(
        'flex flex-col items-center shadow-xl bg-white hover:cursor-pointer hover:brightness-75 transition-all duration-500',
        className,
      )}
      to={link}
    >
      <img className='h-32 w-full object-cover' src={imgSrc} />
      <div className='flex flex-col justify-center text-center py-8 md:py-12 px-4'>
        <h3 className='my-2 text-2xl font-bold'>{title}</h3>
        <p className='text-gray-600 text-lg'>{desc}</p>
      </div>
    </Link>
  )
}

const links = [
  {
    title: 'Shared Car Advertising',
    desc: 'A Dynamic Approach to Neighborhood and City Promotion',
    link: '/blog/dynamic-approach',
    imgSrc: auto1,
  },
  {
    title: 'Why you need to know Shared Car Advertising?',
    desc: 'Good read',
    link: '/blog/why-sca',
    imgSrc: auto2,
  },
  {
    title: 'Rethinking Advertising',
    desc: "OOH's Rise Amidst TV Decline and Streaming Dominance",
    link: '/blog/rethinking-advertising',
    imgSrc: tiff1,
  },
]

const Blog = () => {
  return (
    <Page>
      <Page.Header className='bg-white h-[24rem] md:h-[28rem] xl:h-[28rem]' dark={false}>
        <Page.Header.Title className='text-black'>Blogs</Page.Header.Title>
        <Page.Header.Subtitle className='text-gray-800'>
          {"See what's latest and learn more about our vision"}
        </Page.Header.Subtitle>
      </Page.Header>

      <Section className='pt-0 md:pt-0'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          {links.map((item, i) => (
            <CardLink key={i} {...item} />
          ))}
        </div>
      </Section>

      <Page.Footer />
    </Page>
  )
}

export default Blog
