import { MotionProps } from 'framer-motion'

export const DROPDOWN_MOTION: MotionProps = {
  variants: {
    visible: { height: 'auto' },
    hidden: { height: 0 },
  },
  initial: 'hidden',
  exit: 'hidden',
}
