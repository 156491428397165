import { createContext, ReactNode, useContext, useMemo, useState } from 'react'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { Modal } from 'react-overlays'

type ModalState = {
  component: ReactNode
  setComponent: (b: ReactNode) => void
}

const ModalContext = createContext<ModalState>({
  component: null,
  setComponent: () => {
    return
  },
})

type Props = {
  children: ReactNode
}

const ModalProvider = ({ children }: Props) => {
  const [component, setComponent] = useState<ReactNode>()
  const value = useMemo(() => {
    return {
      component,
      setComponent,
    }
  }, [component])

  return (
    <ModalContext.Provider value={value}>
      {children}
      <Modal
        show={!!component}
        onHide={() => setComponent(undefined)}
        renderBackdrop={(p) => <div {...p} className='fixed inset-0 bg-black opacity-50 z-50' />}
        onBackdropClick={() => setComponent(undefined)}
        className='fixed inset-0 m-auto z-50 max-w-4xl max-h-[600px]'
      >
        <div className='bg-white rounded-2xl p-6 pt-12 w-full h-full'>
          {component}
          <div
            className='absolute right-2 top-2 hover:scale-110 transition-all duration-500 cursor-pointer'
            onClick={() => setComponent(undefined)}
          >
            <IoCloseCircleOutline size={30} />
          </div>
        </div>
      </Modal>
    </ModalContext.Provider>
  )
}

export default ModalProvider

export const useModalContext = () => useContext(ModalContext)
