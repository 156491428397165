import {
  LazyComponentProps,
  LazyLoadImage,
  trackWindowScroll,
} from 'react-lazy-load-image-component'

type Props = {
  images: string[]
} & LazyComponentProps

const LazyView = trackWindowScroll<Props>(({ images, scrollPosition }) => (
  <>
    {images.map((image, i) => (
      <LazyLoadImage key={i} src={image} effect='blur' scrollPosition={scrollPosition} />
    ))}
  </>
))

export default LazyView
