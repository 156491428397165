import { IoLogoApple, IoLogoGooglePlaystore } from 'react-icons/io5'

import app from '@/assets/app.png'
import Section from '@/components/Section'

const GooglePlayLink = () => {
  return (
    <a
      className='flex items-center px-4 m-2 md:m-6 text-lg font-medium md:text-xl text-center bg-black hover:opacity-80 py-4 rounded-full'
      href='https://play.google.com/store/apps/details?id=com.arund.driver&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
    >
      <IoLogoGooglePlaystore className='text-white mr-2' size={26} />
      <div className='text-white'>Play Store</div>
    </a>
  )
}

const AppStoreLink = () => {
  return (
    <a
      className='flex items-center px-4 m-2 md:m-6 text-lg font-medium md:text-xl text-center bg-black hover:opacity-80 py-4 rounded-full'
      href='https://apps.apple.com/ca/app/arund/id6444404647?itsct=apps_box_badge&itscg=30200'
    >
      <IoLogoApple className='text-white mr-2' size={26} />
      <div className='text-white'>App Store</div>
    </a>
  )
}

const MobileApp = () => {
  return (
    <Section className='max-w-7xl'>
      <div className='grid lg:grid-cols-2 place-items-center'>
        <img className='w-[400px]' src={app} />
        <div>
          <Section.Title>Download Now</Section.Title>
          <Section.Subtitle>Available for both Android and iOS.</Section.Subtitle>
          <div className='flex items-center justify-center'>
            <AppStoreLink />
            <GooglePlayLink />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default MobileApp
