import { motion } from 'framer-motion'
import { ReactNode, useState } from 'react'
import Marquee from 'react-fast-marquee'

import { imageMotion, overlayMotion } from './motion'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-coverflow'

type GalleryView = {
  image: string
  title: ReactNode
  description: ReactNode
}

type Props = {
  views: GalleryView[]
}

const GallerySwiper = ({ views }: Props) => {
  const [hovered, setHovered] = useState<string>('')
  return (
    <div className='w-full'>
      <Marquee pauseOnHover autoFill speed={60}>
        {views.map((view, i) => {
          const key = `door-${i}`
          return (
            <motion.div
              key={key}
              className='relative w-[600px] h-[400px]'
              onMouseEnter={() => setHovered(key)}
              onMouseLeave={() => setHovered('')}
            >
              <motion.img
                {...imageMotion}
                animate={hovered === key ? 'hovered' : 'initial'}
                src={view.image}
                className='w-full h-full object-cover'
              />

              <motion.div
                {...overlayMotion}
                className='absolute top-0 left-0 bottom-0 right-0'
                animate={hovered === key ? 'hovered' : 'initial'}
              />
              <div className='absolute z-10 inset-10 md:inset-20 flex flex-col gap-2 justify-center items-center text-center'>
                <div className='text-gray-100 text-3xl md:text-4xl font-semibold'>{view.title}</div>
                <div className='text-gray-300 text-md md:text-lg'>{view.description}</div>
              </div>
            </motion.div>
          )
        })}
      </Marquee>
    </div>
  )
}

export default GallerySwiper
