import { motion, useMotionValue, useSpring, useTransform } from 'framer-motion'
import { MouseEventHandler, useRef } from 'react'

import { BaseUI } from '../types'

const Sticky = ({ children, className }: BaseUI) => {
  const ref = useRef<HTMLDivElement>(null)

  const x = useMotionValue(0)
  const y = useMotionValue(0)

  const transformX = useTransform(x, [0, 1], [-5, 5])
  const transformY = useTransform(y, [0, 1], [-5, 5])

  const translateX = useSpring(transformX)
  const translateY = useSpring(transformY)

  const handleMouseMove: MouseEventHandler = (event) => {
    if (!ref.current) return [0, 0]

    const rect = ref.current.getBoundingClientRect()

    const absolute = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    }

    const percent = {
      x: absolute.x / rect.width,
      y: absolute.y / rect.height,
    }

    x.set(percent.x)
    y.set(percent.y)
  }

  const handleMouseLeave = () => {
    x.set(0.5)
    y.set(0.5)
  }

  return (
    <motion.div
      ref={ref}
      className={className}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      style={{ translateX, translateY }}
    >
      {children}
    </motion.div>
  )
}

export default Sticky
