import { useFormik } from 'formik'

import Button from '@/components/Button'
import InputField from '@/components/Input/InputField'

import { CommonProps } from './types'

const Budget = ({ recordResponse, setStep }: CommonProps) => {
  const formik = useFormik({
    initialValues: {
      budget: '',
    },
    onSubmit: (values) => {
      recordResponse(values)
      setStep((p) => p + 1)
    },
  })

  return (
    <form className='h-full max-w-xl mx-auto' onSubmit={formik.handleSubmit}>
      <div className='flex flex-col h-full'>
        <InputField
          id='budget'
          label='What is the estimated monthly budget?'
          placeholder='Budget'
          value={formik.values.budget}
          onChange={formik.handleChange}
        />
        <div className='mt-auto flex'>
          <Button
            className='w-1/2 mr-6 rounded-lg bg-gray-100 text-black'
            type='button'
            onClick={() => setStep((p) => p - 1)}
          >
            Back
          </Button>
          <Button className='w-1/2 rounded-lg bg-blue-500' type='submit'>
            Next
          </Button>
        </div>
      </div>
    </form>
  )
}

export default Budget
